import { collection, getFirestore, query, orderBy, getDocs } from "firebase/firestore";
import firebaseApp from './provider';

const goveoFirestore = getFirestore(firebaseApp);
const goveoShippingTypesRef = collection(goveoFirestore, "shippingtypes");

export default class GoveoShippingTypeRepository {
    async fetch() {
        let shippingTypes = await getDocs(query(goveoShippingTypesRef, orderBy("sort")));
        return shippingTypes.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id
            };
        });
    }
}
