<template>
  <div>
    <v-stepper-step
      :editable="editable"
      :complete="currentStep > stepNumber"
      :step="stepNumber"
    >
      Tipos de envío para compras Online
      <small>(activación de tipos, tarifas, etc.)</small>
    </v-stepper-step>
    <v-stepper-content :step="stepNumber">
      <v-form ref="shippingDataForm">
        <div v-for="(shippingType, index) in shippingTypes">
          <v-switch
            v-if="shippingType.available && shippingType.show"
            color="primary"
            v-model="shippingType.enabled"
            :disabled="shippingType.disabled"
            :label="shippingType.name"
          ></v-switch>
          <template>
            <div
              v-show="index === 'nationalShippingGls' && shippingType.enabled"
              class="mb-4"
            >
              <v-sheet
                      class="mb-1"
                      width="100%"
                      height="auto"
                      elevation="0"
                      color="#F8F8F8">
                <div class="pt-3 pb-3 pl-2 pr-2">
                  <v-row no-gutters align="center">
                    <v-icon size="20" class="mr-2">mdi-information</v-icon>
                    <h3 class="card-heading">Información</h3>
                  </v-row>
                  <div class="card-terms">
                    Establece un importe a partir del cual el cliente no paga el envío y lo paga el comercio
                  </div>
                </div>
              </v-sheet>
              <v-text-field
                type="number"
                v-model="shippingTypes.nationalShippingGls.freeFrom"
                label="Envío gratuito a partir de:"
                suffix="€"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center content-middle">PESO</th>
                      <th class="text-center content-middle">PENINSULAR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="rate in shippingTypes.nationalShippingGls.rates"
                      :key="rate.weight"
                    >
                      <td class="text-center content-middle">
                        {{ rate.weight }} KG
                      </td>
                      <td class="text-center content-middle">
                        {{ rate.value.toFixed(2) }} €
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
          <template>
            <div
                    v-show="index === 'localMajadahonda' && shippingType.enabled"
                    class="mb-4"
            >
              <v-sheet
                      class="mb-1"
                      width="100%"
                      height="auto"
                      elevation="0"
                      color="#F8F8F8">
                <div class="pt-3 pb-3 pl-2 pr-2">
                  <v-row no-gutters align="center">
                    <v-icon size="20" class="mr-2">mdi-information</v-icon>
                    <h3 class="card-heading">Información</h3>
                  </v-row>
                  <div class="card-terms">
                    Establece un importe a partir del cual el cliente no paga el envío y lo paga el comercio
                  </div>
                </div>
              </v-sheet>
              <v-text-field
                      type="number"
                      v-model="shippingTypes.localMajadahonda.freeFrom"
                      label="Envío gratuito a partir de:"
                      suffix="€"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-center content-middle">PRECIO</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                          v-for="rate in shippingTypes.localMajadahonda.rates"
                          :key="rate.weight"
                  >
                    <td class="text-center content-middle">
                      {{ rate.value.toFixed(2) }} € + IVA
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
          <template>
            <div
              v-show="index === 'delivery' && shippingType.enabled"
              class="mb-4"
            >
              <v-text-field
                type="number"
                v-model="shippingTypes.delivery.price"
                label="Precio del reparto:"
                suffix="€"
              ></v-text-field>
              <!--<v-text-field-->
                <!--type="number"-->
                <!--v-model="shippingTypes.delivery.freeFrom"-->
                <!--label="Envío gratuito a partir de:"-->
                <!--suffix="€"-->
              <!--&gt;</v-text-field>-->
            </div>
          </template>
          <template>
            <div
                    v-show="(index === 'delivery') && shippingType.enabled"
                    class="mb-4"
            >
              <payment-tranches
                      v-model="shippingTypes.delivery.paymentTranches"
                      :initial-tranches="shippingTypes.delivery.paymentTranches || []"
              ></payment-tranches>
            </div>
          </template>
          <template>
            <div
              v-show="(index === 'stuart') && shippingType.enabled"
              class="mb-4"
            >
              <payment-tranches
                v-model="shippingTypes.stuart.paymentTranches"
                :initial-tranches="shippingTypes.stuart.paymentTranches || []"
              ></payment-tranches>
            </div>
          </template>
        </div>

        <div class="mb-3"></div>

        <v-btn block :loading="submitting" color="primary" @click="next">{{
          submitText
        }}</v-btn>
      </v-form>
    </v-stepper-content>
  </div>
</template>

<script>
import PaymentTranches from "../payment-tranches/PaymentTranches.vue";
import GoveoShippingTypeRepository from "../../../core/infra/goveoShippingTypeRepository";
import fetchShippingTypes from "../../../core/app/fetchShippingTypes";

const shippingTypesRepository = new GoveoShippingTypeRepository();

export default {
  components: {
    PaymentTranches
  },
  props: [
    "stepNumber",
    "currentStep",
    "goveoStore",
    "validated",
    "editable",
    "submitText",
    "submitting"
  ],
  mounted: async function() {
    await this.populateShippingTypes();
  },
  data: function() {
    return {
      shippingTypes: {}
    };
  },
  methods: {
    async populateShippingTypes() {
      const shippingTypes = await fetchShippingTypes(shippingTypesRepository);
      let parsedShippingTypes = {};
      shippingTypes.forEach(shippingType => {
        parsedShippingTypes[shippingType.id] = {
          ...shippingType,
          show: this.goveoStore.partner
                  ? shippingType.availableFor &&
                  shippingType.availableFor.includes(this.goveoStore.partner)
                  : shippingType.availableFor &&
                  shippingType.availableFor.includes("all"),
          enabled:
            this.goveoStore.shippingTypes &&
            this.goveoStore.shippingTypes[shippingType.id]
              ? this.goveoStore.shippingTypes[shippingType.id].enabled || false
              : false,
          freeFrom:
            this.goveoStore.shippingTypes &&
            this.goveoStore.shippingTypes[shippingType.id]
              ? this.goveoStore.shippingTypes[shippingType.id].freeFrom || null
              : shippingType.freeFrom
              ? shippingType.freeFrom
              : null,
          price:
            this.goveoStore.shippingTypes &&
            this.goveoStore.shippingTypes[shippingType.id]
              ? this.goveoStore.shippingTypes[shippingType.id].price || null
              : null,
          paymentTranches:
            this.goveoStore.shippingTypes &&
            this.goveoStore.shippingTypes[shippingType.id]
              ? this.goveoStore.shippingTypes[shippingType.id]
                  .paymentTranches || []
              : []
        };
      });
      this.shippingTypes = parsedShippingTypes;
    },
    next() {
      this.$emit("onSelectedShippingTypes", {
        ...this.shippingTypes,
        nationalShippingGls: {
          ...this.shippingTypes.nationalShippingGls,
          freeFrom:
            this.shippingTypes.nationalShippingGls.freeFrom === ""
              ? null
              : this.shippingTypes.nationalShippingGls.freeFrom,
          rates: this.shippingTypes.nationalShippingGls.rates
        },
        localMajadahonda: {
          ...this.shippingTypes.localMajadahonda,
          freeFrom:
                  this.shippingTypes.localMajadahonda.freeFrom === ""
                          ? null
                          : this.shippingTypes.localMajadahonda.freeFrom,
          rates: this.shippingTypes.localMajadahonda.rates
        },
        delivery: {
          ...this.shippingTypes.delivery,
          freeFrom:
            this.shippingTypes.delivery.freeFrom === ""
              ? null
              : this.shippingTypes.delivery.freeFrom,
          price: this.shippingTypes.delivery.price
            ? parseFloat(this.shippingTypes.delivery.price)
            : null
        },
      });
      this.$emit("next");
    }
  }
};
</script>

<style lang="scss">
.required label::after {
  content: "*";
  color: red;
}
.content-middle {
  vertical-align: middle;
}
</style>
