import { collection, getFirestore, doc, getDoc } from "firebase/firestore";
import firebaseApp from './provider';

const goveoFirestore = getFirestore(firebaseApp);
const goveoCategoriesRef = collection(goveoFirestore, "categories");

export default class GoveoCategoryRepository {
  async fetch(categoryId) {
    let category = await getDoc(doc(goveoCategoriesRef, categoryId));

    if (!category.data().subCategories) {
      return [];
    }

    return category.data().subCategories.map(subCategory => {
      return subCategory;
    });
  }
}
