<template>
  <div>
    <v-stepper-step :editable="editable" :complete="currentStep > stepNumber" :step="stepNumber">
      Imagen del escaparate y logo
    </v-stepper-step>
    <v-stepper-content :step="stepNumber">
      <div>
        <v-container>
            <div class="column centered">
                <p class="headline">Foto del escaparate</p>
                <image-uploader
                        @onChange="onMainImageChanged"
                        :placeholder="goveoStore.mainImage||'https://res.cloudinary.com/goveo/image/upload/v1607935134/placeholder_llwhwl_dqxqxu.png'"
                        :cropper-options="cropperOptions"
                        :aspect-ratio="mainImageAspectRatio"
                >
                    <template v-slot:imgbox="slotProps">
                        <div class="img-box">
                            <v-img :src="slotProps.choosedImage || slotProps.placeholder" contain width="360" max-width="360"></v-img>
                        </div>
                    </template>
                </image-uploader>
            </div>
            <br />
            <div class="column centered">
                <p class="headline">Logo del comercio</p>
                <image-uploader
                        @onChange="onAvatarChanged"
                        @onOpenCrop="onOpenCrop"
                        @onCloseCrop="onCloseCrop"
                        :placeholder="goveoStore.avatar||'https://res.cloudinary.com/goveo/image/upload/v1607935134/placeholder_llwhwl_dqxqxu.png'"
                        :cropper-options="cropperOptions"
                        :aspect-ratio="avatarAspectRatio">
                    <template v-slot:imgbox="slotProps">
                        <div class="img-box">
                            <v-avatar size="120">
                                <v-img :src="slotProps.choosedImage || slotProps.placeholder"/>
                            </v-avatar>
                        </div>
                    </template>
                </image-uploader>
            </div>
        </v-container>
      </div>
      <v-btn block :disabled="needValidate && !valid" :loading="submitting" color="primary" @click="next">{{submitText}}</v-btn>
      <v-btn v-if="hasBack" text @click="back">Volver atrás</v-btn>
    </v-stepper-content>
  </div>
</template>

<style lang="scss">
    .column {
        display: flex; flex-direction: column;
        &.centered {
            align-items: center;
        }
    }
</style>

<script>
import ImageUploader from "../image-uploader/ImageUploader.vue";

export default {
  components: { ImageUploader },
  props: ["stepNumber", "currentStep", "editable", "submitText", "hasBack", "goveoStore", "submitting", "needValidate"],
  data: () => ({
    cropperOptions: {
      movable: true,
      scalable: false,
      zoomable: true
    },
    mainImageAspectRatio: 1 / 1,
    avatarAspectRatio: 1 / 1,
    mainImage: null,
    avatar: null
  }),
  watch: {
    mainImage(value) {
      this.$emit("onMainImageSelect", value);
    },
    avatar(value) {
      this.$emit("onAvatarImageSelect", value);
    }
  },
  computed: {
    valid() {
      return this.mainImage && this.avatar;
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next");
    },
    onMainImageChanged(image) {
      this.mainImage = image;
    },
    onAvatarChanged(image) {
      this.avatar = image;
    },
    onOpenCrop() {
      if (!this.$fromWeb) {
        Store.postMessage("close_navbar");
      }
    },
    onCloseCrop() {
      if (!this.$fromWeb) {
        Store.postMessage("open_navbar");
      }
    },
  }
};
</script>
