<template>
    <div>
        <div v-for="(day, dayIndex) in schedule">
            <v-switch
                    v-model="day.enabled"
                    :label="day.name"
                    :value="day.enabled"
                    color="primary"
                    style="margin-left: 8px;"
            ></v-switch>

            <div v-if="day.enabled">
                <v-container fluid v-for="(timeSection, timeSectionIndex) in day.timeSections">
                    <v-row no-gutters>
                        <v-col cols="5">
                            <div style="margin-right: 2px;">
                                <v-select
                                        v-model="timeSection.start"
                                        :items="hours()"
                                        outlined
                                        label="Desde:"
                                ></v-select>
                            </div>
                        </v-col>
                        <v-col cols="5">
                            <v-select
                                    v-model="timeSection.end"
                                    :items="hours(timeSection.start)"
                                    outlined
                                    label="Hasta:"
                            ></v-select>
                        </v-col>
                        <v-col cols="2">
                            <div>
                                <v-btn @click="removeTimeSection(dayIndex, timeSectionIndex)" :ripple="false" icon color="red">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <v-btn :ripple="false" @click="addTimeSection(dayIndex)" color="primary" text block> + Añadir tramo horario</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      props: ["currentSchedule"],
      name: 'ScheduleForm',
      data: function() {
        return {
          schedule: [
            {
              name: "Lunes",
              enabled: this.currentSchedule ? this.currentSchedule[0].enabled : false,
              timeSections: this.currentSchedule ? this.currentSchedule[0].timeSections : []
            },
            {
              name: "Martes",
              enabled: this.currentSchedule ? this.currentSchedule[1].enabled : false,
              timeSections: this.currentSchedule ? this.currentSchedule[1].timeSections : []
            },
            {
              name: "Miércoles",
              enabled: this.currentSchedule ? this.currentSchedule[2].enabled : false,
              timeSections: this.currentSchedule ? this.currentSchedule[2].timeSections : []
            },
            {
              name: "Jueves",
              enabled: this.currentSchedule ? this.currentSchedule[3].enabled : false,
              timeSections: this.currentSchedule ? this.currentSchedule[3].timeSections : []
            },
            {
              name: "Viernes",
              enabled: this.currentSchedule ? this.currentSchedule[4].enabled : false,
              timeSections: this.currentSchedule ? this.currentSchedule[4].timeSections : []
            },
            {
              name: "Sábado",
              enabled: this.currentSchedule ? this.currentSchedule[5].enabled : false,
              timeSections: this.currentSchedule ? this.currentSchedule[5].timeSections : []
            },
            {
              name: "Domingo",
              enabled: this.currentSchedule ? this.currentSchedule[6].enabled : false,
              timeSections: this.currentSchedule ? this.currentSchedule[6].timeSections : []
            },
          ],
        };
      },
      watch: {
        schedule: {
          deep: true,
          handler(value) {
            let referenceWeekDay;

            for (let i = 0; i < value.length; i++) {
              let weekDay = value[i];

              if (weekDay.timeSections.length) {
                referenceWeekDay = weekDay;
                break;
              }
            }

            for (let i = 0; i < value.length; i++) {
              let weekDay = value[i];

              if (referenceWeekDay && (!weekDay.timeSections || !weekDay.timeSections.length)) {

                if (!weekDay.timeSections) {
                  weekDay.timeSections = [];
                }

                weekDay.timeSections.push(referenceWeekDay.timeSections[0]);
              }
            }

            this.$emit("scheduleChanged", value);
          }
        }
      },
      methods: {
        timeConvert(num) {
          const hours = Math.floor(num / 60);
          const minutes = num % 60;
          return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`;
        },
        hours(from = "06:30") {
          const max = 1470;
          const step = 30;
          const nextIndex = 1;
          const steps = max / step;
          let hours = [];

          for (let i = 0; i < steps; i++) {
            let hourInMinutes = ((steps - 1 !== i) ? i : 0) * step;
            let hourView = "";
            hourView = this.timeConvert(hourInMinutes);
            hours.push(hourView);
          }

          if (from) {
            hours.splice(0, (hours.indexOf(from) + nextIndex));
          }

          return hours;
        },
        addTimeSection(dayIndex) {
          if (!this.schedule[dayIndex].timeSections) {
            this.schedule[dayIndex].timeSections = [];
          }

          this.schedule[dayIndex].timeSections.push({
            start: null,
            end: null
          });
        },
        removeTimeSection(dayIndex, timeSectionIndex) {
          this.schedule[dayIndex].timeSections.splice(timeSectionIndex);
        },
      }
    }
</script>
