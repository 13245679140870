<template>
  <div>
    <v-stripe-card
      v-model="paymentMethod"
      create="paymentMethod"
      :customer-data="customerData"
      :api-key="apiKey"
    ></v-stripe-card>
  </div>
</template>

<script>
export default {
  props: ["customerData", "apiKey"],
  data() {
    return {
      paymentMethod: null
    };
  },
  watch: {
    async paymentMethod(value) {
      this.$emit("paymentMethodCreated", value);
    }
  }
};
</script>
