<template>
  <v-app>
    <div class="form-container">
      <v-stepper v-model="currentStep" vertical>
        <extended-data
                :submitting="submitting"
                :submit-text="submitText"
                editable
                :currentStep="currentStep"
                :step-number="1"
                @next="onSave"
                :goveo-store="goveoStore"
        ></extended-data>
        <main-image-step
                :submitting="submitting"
                :goveo-store="goveoStore"
                :submit-text="submitText"
                :need-validate="false"
                editable
                :currentStep="currentStep"
                :step-number="2"
                @next="onSave"
                @back="onBackStep"
                @onMainImageSelect="onMainImageSelected"
                @onAvatarImageSelect="onAvatarSelected"
        ></main-image-step>
        <select-address-step
                :submitting="submitting"
                :submit-text="submitText"
                :is-webview="isWebView"
                editable
                :currentStep="currentStep"
                :step-number="3"
                :goveo-store="goveoStore"
                @stepClicked="selectStep"
                @next="onSave"
                @back="onBackStep"
                @onAddressSelect="onAddressSelected"
        ></select-address-step>
        <billing-data-step
                :submit-text="submitText"
                :currentStep="currentStep"
                :step-number="4"
                editable
                @next="onSave"
                @back="onBackStep"
                :goveo-store="goveoStore"
        ></billing-data-step>
        <select-categories
                :submitting="submitting"
                :submit-text="submitText"
                editable
                :currentStep="currentStep"
                :step-number="5"
                :preselectedCategory="goveoStore.category"
                @next="onSave"
                @back="onBackStep"
                @onSelectCategory="onSelectedCategory"
        ></select-categories>
        <payment-gateway-data
                :submitting="submitting"
                :submit-text="submitText"
                editable
                :currentStep="currentStep"
                :step-number="6"
                @next="onSave"
                @back="onBackStep"
                @invalid="onInvalidPaymentGateway"
                @onEnabledPaymentGateway="onEnabledPaymentGateway"
                :goveo-store="goveoStore"
        ></payment-gateway-data>
        <shipping-data
                :submitting="submitting"
                :submit-text="submitText"
                editable
                :currentStep="currentStep"
                :step-number="7"
                @next="onSave"
                @back="onBackStep"
                @invalid="onInvalidShippingData"
                @onSelectedShippingTypes="onSelectedShippingTypes"
                :goveo-store="goveoStore"
        ></shipping-data>
        <schedule
                :submitting="submitting"
                :submit-text="submitText"
                editable
                :currentStep="currentStep"
                :step-number="8"
                @next="onSave"
                @back="onBackStep"
                @scheduleChanged="onScheduleChanged"
                :goveo-store="goveoStore"
        ></schedule>
        <manage-store-categories
                :submitting="submitting"
                :submit-text="submitText"
                editable
                :currentStep="currentStep"
                :step-number="9"
                :category="goveoStore.category"
                :preselectedSubCategories="goveoStore.subCategories"
                @next="onSave"
                @back="onBackStep"
                @onSelectedSubCategories="onSelectedSubCategories"
        ></manage-store-categories>
      </v-stepper>
    </div>
  </v-app>
</template>

<script>
import * as firebaseApp from "../../core/infra/provider";
import ExtendedData from "../components/steps/ExtendedData.vue";
import BillingDataStep from "../components/steps/BillingDataStep.vue";
import SelectCategories from "../components/steps/SelectCategories.vue";
import SelectAddressStep from "../components/steps/SelectAddressStep.vue";
import MainImageStep from "../components/steps/MainImageStep.vue";
import ShippingData from "../components/steps/ShippingData.vue";
import PaymentGatewayData from "../components/steps/PaymentGatewayData.vue";
import Schedule from "../components/steps/Schedule.vue";

import GoveoStoreRepository from "../../core/infra/goveoStoreRepository";
import editGoveoStore from "../../core/app/editGoveoStore";
import ManageStoreCategories from "../components/steps/ManageStoreCategories";

const storesRepository = new GoveoStoreRepository();

export default {
  name: "edit-form",
  data() {
    return {
      submitting: false,
      submitText: "GUARDAR",
      currentStep: this.$step || 1,
      isWebView: !this.$fromWeb,
      user: {},
      goveoStore: {
        id: this.$store.id || "",
        name: this.$store.name || "",
        email: this.$store.email || "",
        prefixPhoneNumber: this.$store.prefixPhoneNumber || null,
        phoneNumber: this.$store.phoneNumber || "",
        website: this.$store.website || "",
        description: this.$store.description || "",
        address: {
          formattedAddress: this.$store.address.formattedAddress,
          geoHash: this.$store.address.geoHash,
          position: {
            latitude: this.$store.address.geoPoint.latitude,
            longitude: this.$store.address.geoPoint.longitude
          }
        },
        isWhatsapp: this.$store.isWhatsapp || false,
        isAppointment: this.$store.isAppointment || false,
        mainImage: this.$store.mainImage,
        avatar: this.$store.avatar,
        category: this.$store.categoryId,
        establishmentMeasures: this.$store.establishmentMeasures || 0,
        isMall: this.$store.isMall || false,
        isBrandedStore: this.$store.isBrandedStore || false,
        companyName: this.$store.companyName || "",
        cif: this.$store.cif || "",
        billingAddress: this.$store.billingAddress || "",
        billingEmail: this.$store.billingEmail || "",
        billingPhoneNumber: this.$store.billingPhoneNumber || "",
        shippingTypes: this.$store.shippingTypes || null,
        paymentGateway: this.$store.paymentGateway || null,
        paymentData: this.$store.paymentData || null,
        schedule: this.$store.schedule || null,
        subCategories: this.$store.subCategories || [],
        partner: this.$store.partner || null
      },
      subCategories: []
    };
  },
  beforeMount: function() {
    this.user = firebaseApp.auth().currentUser;
  },
  created() {
    if (!this.$fromWeb) {
      Store.postMessage("initialized");
    }
  },
  methods: {
    selectStep(step) {
      this.currentStep = step;
    },
    onBackStep() {
      this.currentStep -= 1;
    },
    async onSave() {
      this.submitting = true;
      try {
        for (let key in this.goveoStore.shippingTypes) {
          let shippingType = { ...this.goveoStore.shippingTypes[key] };

          if (shippingType.freeFrom) {
            shippingType.freeFrom = parseFloat(shippingType.freeFrom);
          }

          if (shippingType.rates) {
            let parsedShippingRates = shippingType.rates;
            parsedShippingRates.forEach((rate, index) => {
              parsedShippingRates[index].value = parseFloat(rate.value);
            });
            shippingType.rates = parsedShippingRates;
          }

          if (shippingType.paymentTranches) {
            let parsedPaymentTranches = shippingType.paymentTranches.map((tranche) => {
              return {
                from: parseFloat(tranche.from),
                to: parseFloat(tranche.to) || null,
                percentage: parseFloat(tranche.percentage)
              };
            });
            shippingType.paymentTranches = parsedPaymentTranches;
          }

          this.goveoStore.shippingTypes[key] = {...shippingType};
        }

        const store = {
          id: this.$store.id,
          name: this.goveoStore.name,
          description: this.goveoStore.description,
          address: {
            geoPoint: {
              latitude: this.goveoStore.address.position.latitude,
              longitude: this.goveoStore.address.position.longitude
            },
            geoHash: this.goveoStore.address.geoHash,
            formattedAddress: this.goveoStore.address.formattedAddress
          },
          website: this.goveoStore.website,
          mainImage: this.goveoStore.mainImage,
          avatar: this.goveoStore.avatar,
          category: this.goveoStore.category,
          establishmentMeasures: this.goveoStore.establishmentMeasures,
          isMall: this.goveoStore.isMall,
          isBrandedStore: this.goveoStore.isBrandedStore,
          isWhatsapp: this.goveoStore.isWhatsapp,
          isAppointment: this.goveoStore.isAppointment,
          prefixPhoneNumber: this.goveoStore.prefixPhoneNumber,
          phoneNumber: this.goveoStore.phoneNumber,
          email: this.goveoStore.email.trim(),
          companyName: this.goveoStore.companyName,
          cif: this.goveoStore.cif,
          billingAddress: this.goveoStore.billingAddress,
          billingEmail: this.goveoStore.billingEmail.trim(),
          billingPhoneNumber: this.goveoStore.billingPhoneNumber,
          shippingTypes: this.goveoStore.shippingTypes,
          paymentGateway: this.goveoStore.paymentGateway,
          paymentData: this.goveoStore.paymentData,
          schedule: this.goveoStore.schedule,
          subCategories: this.subCategories,
        };

        const updatedStore = await editGoveoStore(store, {
          goveoStoreRepository: storesRepository
        });
        this.$notify("La tienda se editó correctamente", "success");
        if (!this.$fromWeb) {
          Store.postMessage("edited");
        }
      } catch (e) {
        console.log(e);
        this.$notify(
          "Hubo un problema al editar la tienda. Pruebe de nuevo más tarde.",
          "error"
        );
      }

      this.submitting = false;
    },
    onAddressSelected(place) {
      this.goveoStore.address = place;
    },
    onSelectedCategory(selectedCategory) {
      this.goveoStore.category = selectedCategory.id;
      this.goveoStore.categoryType = selectedCategory.type;
    },
    onSelectedSubCategories(selectedSubCategories) {
      this.subCategories = selectedSubCategories;
    },
    onMainImageSelected(mainImage) {
      this.goveoStore.mainImage = mainImage;
    },
    onAvatarSelected(avatar) {
      this.goveoStore.avatar = avatar;
    },
    onSelectedShippingTypes(shippingTypes) {
      this.goveoStore.shippingTypes = shippingTypes;
    },
    onScheduleChanged(schedule) {
      this.goveoStore.schedule = schedule;
    },
    onInvalidShippingData() {
      this.$notify(
        "Debe rellenar todos los campos para activar el Envío nacional",
        "error"
      );
    },
    onEnabledPaymentGateway(paymentGateway) {
      this.goveoStore.paymentGateway = paymentGateway;
    },
    onInvalidPaymentGateway() {
      this.$notify(
        "Debe rellenar todos los campos para activar la pasarela de pago",
        "error"
      );
    },
  },
  components: {
    ExtendedData,
    ManageStoreCategories,
    PaymentGatewayData,
    ShippingData,
    SelectCategories,
    SelectAddressStep,
    MainImageStep,
    Schedule,
    BillingDataStep
  }
};
</script>
