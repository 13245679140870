<template>
  <div>
    <div @click="inputImg()">
      <slot
        name="imgbox"
        v-bind:choosedImage="choosedImage"
        v-bind:placeholder="placeholder"
      ></slot>
    </div>
    <input
      v-show="false"
      ref="input"
      type="file"
      accept="image/*"
      @change="inputImgChange($event)"
    />
    <transition name="slim-fade">
      <div v-show="cropShow" class="crop-wrap">
        <SlimCropper
          ref="cropper"
          :src="choosedImage"
          :aspect-ratio="aspectRatio"
          :cropperOptions="cropperOptions"
        ></SlimCropper>
        <div class="btn-box">
          <button @click="hideCrop"><pre> Cancelar</pre></button>
          <button @click="submitCrop">OK</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const getObjectURL = file => {
  let url;
  if (window.URL) {
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL) {
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
};

function Resize() {}

Resize.prototype = {
  init: function(outputQuality) {
    this.outputQuality = (outputQuality === 'undefined' ? 1 : outputQuality);
  },

  photo: function(file, maxSize, outputType, callback) {
    var _this = this;
    var reader = new FileReader();
    reader.onload = function (readerEvent) {
      _this.resize(readerEvent.target.result, maxSize, outputType, callback);
    }
    reader.readAsDataURL(file);
  },

  resize: function(dataURL, maxSize, outputType, callback) {
    var _this = this;
    var image = new Image();
    image.onload = function (imageEvent) {
      // Resize image
      var canvas = document.createElement('canvas'),
              width = image.width,
              height = image.height;
      // if (width > height) {
      //   if (width > maxSize) {
      //     height *= maxSize / width;
      //     width = maxSize;
      //   }
      // } else {
      //   if (height > maxSize) {
      //     width *= maxSize / height;
      //     height = maxSize;
      //   }
      // }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      _this.output(canvas, outputType, callback);
    }
    image.src = dataURL;
  },

  output: function(canvas, outputType, callback) {

    switch (outputType) {
      case 'file':
        canvas.toBlob(function (blob) {
          callback(blob);
        }, 'image/jpeg', 1);
        break;

      case 'dataURL':
        callback(canvas.toDataURL('image/jpeg', 1));
        break;
    }
  }
};

const resizer = new Resize();

const resizeImage = function(img, maxSize) {
  return new Promise((resolve) => {
    resizer.resize(getObjectURL(img), maxSize, 'file', function(resizedImage) {
      resolve(resizedImage);
    });
  });
};

export default {
  props: ["aspectRatio", "placeholder", "cropperOptions"],
  data() {
    return {
      choosedImage: null,
      croppedImage: null,
      cropShow: false
    };
  },
  watch: {
    croppedImage(image) {
      this.$emit("onChange", image);
    }
  },
  methods: {
    async inputImg() {
      this.$refs.input.click();
    },
    inputImgChange(e) {
      try {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
          this.choosedImage = e.target.result;
          this.showCrop();
        };
      } catch (e) {
        this.$notify(
                "La imagen seleccionada tiene un formato inválido. Prueba con otra.",
                "error"
        );
      }
    },
    showCrop() {
      this.$emit("onOpenCrop");
      this.cropShow = true;
    },
    hideCrop() {
      this.$emit("onCloseCrop");
      this.cropShow = false;
      this.$refs.input.value = "";
      this.choosedImage = null;
    },
    async submitCrop() {
      try {
        this.$emit("onCloseCrop");
        this.cropShow = false;
        this.$refs.input.value = "";
        let img = await this.$refs.cropper.getCroppedBlob();
        img = await resizeImage(img, 1080);
        this.choosedImage = getObjectURL(img);
        this.croppedImage = img;
      } catch (e) {
        this.$notify(
                "La imagen seleccionada tiene un formato inválido.",
                "error"
        );
      }
    }
  }
};
</script>

<style lang="scss">
$headerHeight: 44px;
$baseColor: #409eff;
$bgColor: #fafafa;

.img-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 20px;

  .img-item,
  .add-btn {
    width: 150px;
    height: 150px;
    background: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .add-btn {
    > img {
      width: 24px;
      height: 24px;
    }
  }
}

.submit-btn {
  margin: 60px auto;
  display: block;
  width: 70%;
  height: 60px;
  background: $baseColor;
  border-radius: 60px;
  font-size: 20px;
  color: #fff;
  font-weight: 500;

  &:disabled {
    background: #ededed;
    color: #cccccc;
  }
}

.crop-wrap {
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #000;

  .btn-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;

    > button {
      width: 60px;
      height: 100%;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
    }

    & pre {
      background-color: transparent;
      color: #FFF;
    }
  }
}

.slim-fade-enter-active,
.slim-fade-leave-active {
  transition: all 0.4s ease;
}

.slim-fade-enter,
.slim-fade-leave-to {
  opacity: 0;
  transform-origin: top;
  transform: translateY(100%);
}
</style>
