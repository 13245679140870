<template>
    <div>
        <v-container fluid>
            <h2>Mis facturas:</h2>
        </v-container>
        <v-simple-table class="invoices-table" v-if="invoices">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Fecha</th>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in invoices" :key="item.id">
                    <td>{{ formatDate(item.createdAt) }}</td>
                    <td>{{ (item.type === 'commission') ? "Comisiones" : "Suscripción" }}</td>
                    <td>
                        <a @click="downloadInvoice(index, item.url)">
                            <v-btn tile outlined color="#eb5e2d" :loading="item.loading">
                                <v-icon left>mdi-eye</v-icon> VER
                            </v-btn>
                        </a>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-container fluid v-else>
            <p>
                No tiene ninguna factura todavía
            </p>
        </v-container>
    </div>
</template>

<style lang="scss">
    .invoices-table {
        th, td {
            vertical-align: middle;
        }
    }
</style>

<script>
import moment from "moment";
import firebaseApp from '../../core/infra/provider';
import { getStorage, getDownloadURL } from "firebase/storage";
const storage = getStorage(firebaseApp);

    export default {
      created(){
        if (this.invoices) {
          this.invoices.sort(function (x, y) {
            return x.createdAt.seconds - y.createdAt.seconds;
          });
        }
      },
      data: function() {
        return {
          invoices: (this.$store.paymentData) ? this.$store.paymentData.invoices : null
        };
      },
      methods: {
        formatDate(invoiceDate) {
          return moment(invoiceDate.toDate()).locale('es').format('DD MMMM YYYY');
        },
        formatPrice(price) {
          return `${price.toFixed(2)} €`;
        },
        async downloadInvoice(index, url) {
          this.invoices[index].loading = true;
          window.location.href = await getDownloadURL(storage.refFromURL(url));
          this.invoices[index].loading = false;
        }
      }
    }
</script>
