import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import firebaseApp from './provider';

const goveoFirestore = getFirestore(firebaseApp);
const goveoUsersRef = collection(goveoFirestore, "users");

export default class GoveoCategoryRepository {
  async getByEmail(email) {

    const q = query(goveoUsersRef, where("email", "==", email));
    const users = await getDocs(q);

    if (users.docs.length === 0) {
      return null;
    }
    return users.docs[0];
  }

  // update(publisher, userId) {
  //   let params = {
  //     username: publisher.username,
  //     name: publisher.name,
  //     avatar: publisher.avatar
  //   };
  //
  //   if (publisher.bio) {
  //     params.bio = publisher.bio;
  //   }
  //
  //   if (publisher.link) {
  //     params.link = publisher.link;
  //   }
  //
  //   if (publisher.instagram) {
  //     params.instagram = publisher.instagram;
  //   }
  //
  //   return goveoUsersRef.doc(userId).update(params);
  // }
}
