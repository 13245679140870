<template>
  <div>
    <v-stepper-step
      :editable="editable"
      :complete="currentStep > stepNumber"
      :step="stepNumber"
    >
      Resumen del registro
    </v-stepper-step>
    <v-stepper-content :step="stepNumber">
      <div>
        <div class="mb-2">
          <span>Plan seleccionado:</span>
        </div>
        <v-sheet
          class="mb-1"
          v-if="selectedPlan"
          width="100%"
          height="auto"
          elevation="0"
          color="#F8F8F8"
        >
          <div class="pt-3 pb-3 pl-2 pr-2">
            <span
              class="card-badge"
              v-if="goveoStore.coupon && goveoStore.coupon.commercial"
              >Código comercial {{ goveoStore.coupon.id }}</span
            >
            <span
              class="card-badge"
              v-if="goveoStore.coupon && !goveoStore.coupon.noChanges"
              >Promoción {{ goveoStore.coupon.name }}</span
            >
            <h3 class="card-heading">{{ selectedPlan.name }}</h3>
            <template v-if="selectedPlan && !selectedPlan.external">
              <h4 class="card-subheading">
                <p v-if="!goveoStore.coupon || goveoStore.coupon.noChanges">
                  <strong>{{ amountWithoutTax.toFixed(2) }} €</strong> / al
                  {{ this.intervalText }} (+ IVA)
                </p>
                <p v-else>
                  <strong
                  >{{
                      goveoStore.coupon.before
                          ? (
                              amountWithoutTax -
                              amountWithoutTax *
                              (goveoStore.coupon.percentOff / 100)
                          ).toFixed(2)
                          : amountWithoutTax.toFixed(2)
                    }}
                    €</strong
                  >
                  / {{ goveoStore.coupon.description }}
                  <s v-if="goveoStore.coupon.duration === 'forever'">{{
                      goveoStore.coupon.before ? amountWithTax.toFixed(2) : ""
                    }}</s>
                  <span v-else>{{
                      goveoStore.coupon.before ? amountWithTax.toFixed(2) : ""
                    }}</span>
                  (+ IVA)
                </p>
                <p>
                  <strong>Total:</strong> {{ amountWithTax.toFixed(2) }} € / al
                  {{ this.intervalText }}
                </p>
                <div class="plan-description" v-if="selectedPlan.description">
                  <div
                      class="mb-2"
                      v-for="(description, key) in selectedPlan.description"
                      :key="key"
                  >
                    <span>{{ description }}</span>
                  </div>
                  <div class="card-heading text-center">
                    <br />
                    <a
                        target="_blank"
                        :href="
                      isAupa()
                        ? 'https://res.cloudinary.com/goveo/image/upload/v1622532295/avatar_68b2a93b-5f7d-4c88-bcfa-9d491c9061fa.jpg'
                        : 'https://res.cloudinary.com/dhostuuwp/image/upload/v1700121462/descubre_lo_mejoro_cerca_de_ti4_omaobo.jpg'
                    "
                    >
                      <img
                          :src="
                        isAupa()
                          ? 'https://res.cloudinary.com/goveo/image/upload/v1622532295/avatar_68b2a93b-5f7d-4c88-bcfa-9d491c9061fa.jpg'
                          : 'https://res.cloudinary.com/dhostuuwp/image/upload/v1700121462/descubre_lo_mejoro_cerca_de_ti4_omaobo.jpg'
                      "
                          style="width: 260px;"
                      />
                    </a>
                    <p>Siguenos y comparte esta gráfica en Redes Sociales</p>
                    <!--                  <a href="https://es.goveo.app/aupa#promo" target="_blank" v-if="isAupa()">https://es.goveo.app/aupa#promo</a>-->
                    <!--                  <a href="https://es.goveo.app/tiendas#promo" target="_blank" v-else>https://es.goveo.app/tiendas#promo</a>-->
                  </div>
                </div>
              </h4>
            </template>
          </div>
        </v-sheet>

        <div v-if="isPaidPlan">
          <h3 class="mt-5 mb-0">Pagar:</h3>
          <payment
            :api-key="$envConfig.stripePublicKey"
            :customer-data="costumerData"
            @paymentMethodCreated="onPaymentMethodCreated"
          ></payment>
        </div>
      </div>
      <div>
        <v-checkbox
          v-model="termsAccepted"
          color="primary"
          style="margin-left: 8px;"
        >
          <template v-slot:label>
            <div>
              Afirma haber leído las
              <a @click.stop="launchConditionsFromApp($e)" v-if="!$fromWeb">
                Condiciones de Uso
              </a>
              <a
                v-else
                target="_blank"
                href="https://goveo.app/conditions-user"
                @click.stop
              >
                Condiciones de Uso
              </a>
              para tiendas.
            </div>
          </template>
        </v-checkbox>
      </div>
      <v-btn
        block
        :disabled="!validForm"
        :loading="submitting"
        color="primary"
        @click="onSubmit"
        >Finalizar</v-btn
      >
    </v-stepper-content>
  </div>
</template>

<style>
.card-badge {
  display: inline-block;
  margin-bottom: 12px;
  padding: 0.3em 0.6em;
  background: #eb5e2d;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
}

.card-heading {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.card-subheading {
  font-size: 14px;
  font-weight: 400;
}

.card-terms {
  margin: 12px 0 4px 0;
  font-size: 12px;
}
</style>

<script>
import Payment from "../payment/Payment.vue";

export default {
  components: {
    Payment
  },
  props: [
    "stepNumber",
    "currentStep",
    "validatingCoupon",
    "validatedCoupon",
    "validatingSocial",
    "validatedSocial",
    "goveoStore",
    "editable",
    "submitting",
    "selectedPlan",
    "interval"
  ],
  data: function() {
    return {
      termsAccepted: false,
      paymentMethod: null,
      insertedCoupon: "",
      socialLinks: {
        facebook: "",
        instagram: ""
      }
    };
  },
  computed: {
    costumerData() {
      return {
        email: this.goveoStore.email.trim(),
        name: `${this.goveoStore.name} (${this.goveoStore.address.formattedAddress})`
      };
    },
    validForm() {
      if (
        (this.goveoStore.coupon && this.goveoStore.coupon.noCheckout) ||
        (this.selectedPlan && this.selectedPlan.amount === 0)
      ) {
        return this.termsAccepted;
      }

      return this.termsAccepted && this.paymentMethod;
    },
    isPaidPlan() {
      if (this.goveoStore.coupon && this.goveoStore.coupon.noCheckout) {
        return false;
      }

      return this.selectedPlan && this.selectedPlan.amount;
    },
    amountWithTax() {
      return this.selectedPlan.amount * 1.21;
    },
    amountWithoutTax() {
      return this.selectedPlan.amount;
    },
    intervalText() {
      let intervalText = "mes";

      switch (this.selectedPlan.interval) {
        case "year":
          intervalText = "año";
          break;
        case "month":
          if (this.selectedPlan.intervalCount === 6) {
            intervalText = "semestre";
          } else {
            intervalText = "mes";
          }
          break;
      }

      return intervalText;
    }
  },
  watch: {
    insertedCoupon(value) {
      this.$emit("insertedCoupon", value);
    },
    socialLinks: {
      deep: true,
      handler: function(value) {
        this.$emit("insertedSocial", value);
      }
    }
  },
  methods: {
    isAupa() {
      return (
        this.goveoStore.coupon && this.goveoStore.coupon.id.includes("aupa")
      );
    },
    back() {
      this.$emit("back");
    },
    launchConditionsFromApp(e) {
      if (e) e.preventDefault();
      Store.postMessage("conditions");
    },
    onPaymentMethodCreated(paymentMethod) {
      this.paymentMethod = paymentMethod;
      this.$emit("paymentMethodCreated", paymentMethod);
    },
    validateCoupon() {
      this.$emit("validateCoupon");
    },
    validateSocial() {
      this.$emit("validateSocial");
    },
    onSubmit() {
      this.$emit("onSubmit");
    }
  }
};
</script>
