import axios from "axios";

const CLOUD_NAME = "goveo";
export async function upload(imageUrl) {
  let formData = new FormData();
  formData.append("api_key", "298169774887637");
  formData.append("file", imageUrl);
  formData.append("upload_preset", "b0r3ruj3");

  const result = await axios.post(
    `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`,
    formData
  );

  return result.data;
}
