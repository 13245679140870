<template>
    <v-app>
        <div>Toma de control</div>

        <div v-if="store !== null">
            <p>{{ store.name }}</p>
            <p>Is manager: {{isManager}}</p>

            <button @click="notNow" type="button" :disabled="isManager">Ahora no</button>
            <button @click="takeControl" type="button" :disabled="isManager">Tomar control</button>
        </div>
        <div v-else>
            <p>Loading...</p>
        </div>
    </v-app>
</template>

<script>

    import { useStoreControl } from '../use/useStoreControl'

    export default {
        name: 'control',
        data () {
          return {
            store: null,
            user: null,
          }
        },
        beforeMount: async function () {
            this.store = await useStoreControl().getStore(this.$atOrigin);
            this.user = this.$user;
        },
        computed: {
          isManager() {
            if (this.store === null || !this.user.managerOf) {
              return false;
            }

            return this.user.managerOf.includes(this.store.id);
          }
        },
        methods: {
              async notNow() {
                const idToken = await this.$firebaseUser.user.getIdToken();
                await useStoreControl().notNow(this.$atOrigin, idToken);
              },
            async takeControl() {
                const idToken = await this.$firebaseUser.user.getIdToken();
                await useStoreControl().takeStoreControl(this.$atOrigin, idToken);
                this.user.managerOf.push(this.store.id);
            }
        },
        components: {}
    }
</script>
