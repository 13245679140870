<template>
    <div>
        <div class="text-center">
            <v-dialog
                    v-model="opened"
                    persistent
                    width="500"
            >
                <v-card>
                    <v-card-text class="pt-5">
                        <div class="d-flex justify-center align-center mb-5">
                            <img src="@/assets/time.png" style="width: 220px;">
                            <img src="@/assets/envelope.png" style="width: 120px; height: 120px;">
                        </div>
                        <p class="text-h5 font-weight-medium" style="color: #212121;">Gracias por registrar su comercio!</p>
                        <span class="text-body-1">En breve le enviaremos por email su aprobación para estar presente en Goveo.</span>
                        <div class="mb-2"></div>
                        <span class="text-caption">(Goveo se reserva el derecho de admisión por motivos de mercado y calidad)</span>
                        <p class="text-center mt-5 text-body-1">Descarga la app desde los siguientes enlaces:</p>
                        <div class="d-flex justify-center align-center">
                            <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=app.goveo.android" target="_blank">
                                <img src="@/assets/android_download.png" class="download-badge android" />
                            </a>
                            <div class="mr-3"></div>
                            <a href="https://apps.apple.com/us/app/goveo/id1480569862?l=es&ls=1" target="_blank">
                                <img src="@/assets/ios_download.svg" class="download-badge ios" />
                            </a>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="close"
                        >
                            Terminar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
  export default {
    props: ["opened"],
    methods: {
     close() {
       this.$emit("closed");
     }
    }
  };
</script>

<style type="scss">
    h2 {
        margin: 0;
    }
    .success {
        border: solid 1px green;
    }
    .error {
        border: solid 1px red;
    }

    .submit-btn {
        letter-spacing: initial !important;
        text-transform: initial !important;
        font-weight: 600 !important;
        font-size: 15px !important;
    }

    .download-badge.android {
        height: 50px;
    }

    .download-badge.ios {
        height: 51px;
    }
</style>
