import axios from "axios";

export function ApiClient(endpoint, userToken) {
  const instance = axios.create({
    baseURL: endpoint
  });
  instance.interceptors.request.use(function(config) {
    config.headers["Authorization"] = `Bearer ${userToken}`;
    return config;
  });

  this.get = function(url) {
    return instance.get(url);
  };

  this.post = function(url, data) {
    return instance.post(url, data);
  };
}
