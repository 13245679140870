<template>
  <div>
    <v-stepper-step
      :editable="editable"
      :complete="currentStep > stepNumber"
      :step="stepNumber"
    >
      Seleccionar suscripción
    </v-stepper-step>
    <v-stepper-content :step="stepNumber">
      <!--<switcher :options="options" v-model="interval"></switcher>-->

      <toggle-switch
        :options="(goveoStore.coupon && goveoStore.coupon.commercial) ? switcherCommercialOptions : switcherOptions"
        @change="intervalChanged($event.value)"
        :value="intervalSwitchValue"
        name="intervalSelection"
      ></toggle-switch>

      <div v-if="loadingPlans" class="text-center mt-6 mb-6">
        <v-progress-circular
          indeterminate
          :size="70"
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="mb-3">
        <swiper :options="swiperOptions">
          <template v-for="plan in plans">
            <swiper-slide v-bind:key="plan.id">
              <v-card
                @click="selectPlan(plan)"
                outlined
                :ripple="false"
                :class="{
                  'plan-container': true,
                  'pa-3': true,
                  'selected-plan': plan.id === selectedPlanId
                }"
              >
                <div
                  style="display: flex; flex-direction: row; margin-top: 8px;"
                >
                  <span
                    style="flex-grow: 1; text-transform: uppercase; font-size: 12px; font-weight: 600;"
                    >{{ plan.name }}</span
                  >
                  <div
                    style="position: absolute; top: -10px; left: 0; z-index: 999;"
                  >
                    <span class="card-badge" v-if="plan.promo">Promoción</span>
                  </div>
                  <input
                    type="radio"
                    class="option-input radio"
                    :value="plan.id"
                    v-model="selectedPlanId"
                  />
                </div>
                <div
                  class="mt-6"
                  style="position: relative; text-align: center;"
                >
                  <span>
                    <strong v-if="discount(plan)" style="font-size: 20px;">{{(discount(plan)).toFixed(2)}}€ </strong>
                    <strong v-if="!discount(plan)" style="font-size: 20px;">{{(!plan.coupons ? plan.amount : 0).toFixed(2)}}€</strong>
                    <span v-if="discount(plan)" style="font-size: 12px; text-decoration: line-through red;">{{(!plan.coupons ? plan.amount : 0).toFixed(2)}}€</span>
                    / al {{ intervalText }}
                  </span>
                  <div style="position:absolute; top: 24px; left: 0; right: 0;">
                    <small v-if="plan.coupons">
                      (Despues {{ plan.amount.toFixed(2) }} € / al
                      {{ intervalText }})
                    </small>
                  </div>
                </div>
                <div class="mt-8 plan-description">
                  <div
                    class="mb-2"
                    style="text-align: center;"
                    v-for="description in plan.description"
                  >
                    <span>{{ description }}</span>
                  </div>
                </div>
              </v-card>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <v-btn
        block
        :disabled="!validForm"
        :loading="submitting"
        color="primary"
        @click="next"
        >{{ submitText }}</v-btn
      >
    </v-stepper-content>
  </div>
</template>

<style>
.plan-container {
  width: 225px;
  height: 315px;
}
.plan-container,
.plan-container:focus {
  display: flex !important;
  flex-direction: column;
  background-color: #fff !important;
}

.plan-price {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plan-description {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
}

.selected-plan {
  border: 2px solid #eb5e2d !important;
}

@keyframes click-wave {
  0% {
    height: 20px;
    width: 20px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 40px;
    width: 40px;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
  }
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  height: 20px;
  width: 20px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: #eb5e2d;
}
.option-input:checked::before {
  height: 20px;
  width: 20px;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 13.333335px;
  text-align: center;
  line-height: 20px;
}
.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #eb5e2d;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  color: #fff;
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}

.swiper-container {
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  width: 250px;
}
</style>

<script>
import { ToggleSwitch } from "vuejs-toggle-switch";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    ToggleSwitch
  },
  directives: {
    swiper: directive
  },
  props: [
    "stepNumber",
    "currentStep",
    "goveoStore",
    "editable",
    "submitting",
    "submitText",
    "plans",
    "onSelectPlan",
    "interval",
    "loadingPlans"
  ],
  data: function() {
    return {
      selectedPlanId: this.plans ? this.plans[0].id : null,
      selectedPlan: null,
      swiperOptions: {
        slidesPerView: 'auto',
        grabCursor: true,
        freeMode: true,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination'
        }
      },

      switcherOptions: {
        layout: {
          color: 'black',
          backgroundColor: 'lightgray',
          selectedColor: 'white',
          selectedBackgroundColor: 'green',
          borderColor: 'black',
          fontWeight: 'normal',
          fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: true
        },
        size: {
          fontSize: 1,
          height: 2,
          padding: 0.3,
          width: 17
        },
        items: {
          delay: .4,
          disabled: false,
          labels: [
            {name: 'Mensual', color: 'white', backgroundColor: '#eb5e2d'},
            //{name: 'Semestral', color: 'white', backgroundColor: '#eb5e2d'},
            {name: 'Anual', color: 'white', backgroundColor: '#eb5e2d'},
          ]
        }
      },

      switcherCommercialOptions: {
        layout: {
          color: 'black',
          backgroundColor: 'lightgray',
          selectedColor: 'white',
          selectedBackgroundColor: 'green',
          borderColor: 'black',
          fontWeight: 'normal',
          fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: true
        },
        size: {
          fontSize: 1,
          height: 2,
          padding: 0.3,
          width: 17
        },
        items: {
          delay: .4,
          disabled: false,
          labels: [
            {name: 'Semestral', color: 'white', backgroundColor: '#eb5e2d'},
            {name: 'Anual', color: 'white', backgroundColor: '#eb5e2d'},
          ]
        }
      },
    };
  },
  watch: {
    selectedPlanId(value) {
      this.selectedPlan = this.plans[
        this.plans.findIndex(plan => plan.id === value)
      ];
    },
    selectedPlan(selectedPlan) {
      this.$emit("onSelectPlan", selectedPlan);
    }
  },
  computed: {
    validForm() {
      return this.selectedPlan != null;
    },
    intervalSwitchValue() {
      let intervalText = "Mensual";

      switch (this.interval) {
        case "year":
          intervalText = "Anual";
          break;
        case "biannual":
          intervalText = "Semestral";
          break;
      }

      return intervalText;
    },
    intervalText() {
      let intervalText = "mes";

      switch (this.interval) {
        case "year":
          intervalText = "año";
          break;
        case "biannual":
          intervalText = "semestre";
          break;
      }

      return intervalText;
    }
  },
  methods: {
    discount(plan) {
      if (this.goveoStore.coupon && this.goveoStore.coupon.percentOff) {
        return plan.amount - (plan.amount * (this.goveoStore.coupon.percentOff / 100));
      } else if (this.goveoStore.coupon && this.goveoStore.coupon.amountOff) {
        return plan.amount - this.goveoStore.coupon.amountOff;
      }
      return 0;
    },
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next");
    },
    selectPlan(plan) {
      this.selectedPlanId = plan.id;
    },
    onSubmit() {
      this.$emit("onSubmit");
    },
    intervalChanged(value) {
      let intervalValue = "month";

      switch (value) {
        case "Mensual":
          intervalValue = "month";
          break;
        case "Semestral":
          intervalValue = "biannual";
          break;
        case "Anual":
          intervalValue = "year";
      }

      this.$emit("changedInterval", intervalValue);
    }
  }
};
</script>

<style>
.card-badge {
  display: inline-block;
  margin-bottom: 12px;
  padding: 0.3em 0.6em;
  background: #eb5e2d;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
}
</style>
