export default {
  api: process.env.VUE_APP_API_HOST,
  storeManager: process.env.VUE_APP_STORE_MANAGER_HOST,
  goveoIdSignup: process.env.VUE_APP_GOVEOID_SIGNUP,
  goveoIdLogin: process.env.VUE_APP_GOVEOID_LOGIN,
  stripeClientId: process.env.VUE_APP_STRIPE_CLIENT_ID,
  stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
  firebaseConfig: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
  }
};
