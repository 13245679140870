import firebaseApp from './provider';
import { collection, getFirestore, arrayUnion, updateDoc, doc, getDoc } from "firebase/firestore";

const goveoFirestore = getFirestore(firebaseApp);
const usersRef = collection(goveoFirestore, "users");

import { upload } from "../cloudinary/cloudinary";

export default class UserRepository {
  addStoreManagement(userId, storeId) {
    return updateDoc(doc(usersRef, userId), {
      managerOf: arrayUnion(storeId)
    });
  }

  async isPublisher(userId) {
    const user = await getDoc(doc(usersRef, userId));
    return user.data().isPublisher;
  }

  async getPublisher(userId) {
    const user = await getDoc(doc(usersRef, userId));
    return user.data();
  }

  async update(publisher, userId) {
    const avatar = await upload(publisher.avatar);

    let params = {
      username: publisher.username,
      name: publisher.name,
      avatar: avatar["secure_url"],
      isPublisher: true,
      verified: false
    };

    if (publisher.bio) {
      params.bio = publisher.bio;
    }

    if (publisher.link) {
      params.link = publisher.link;
    }

    if (publisher.instagram) {
      params.instagram = publisher.instagram;
    }

    return updateDoc(doc(usersRef, userId), params);
  }

  async edit(publisher, userId) {
    let params = {
      username: publisher.username,
      name: publisher.name,
    };

    if (publisher.avatar) {
      const avatar = await upload(publisher.avatar);
      params.avatar = avatar["secure_url"];
    }

    if (publisher.bio) {
      params.bio = publisher.bio;
    }

    if (publisher.link) {
      params.link = publisher.link;
    }

    if (publisher.instagram) {
      params.instagram = publisher.instagram;
    }

    return updateDoc(doc(usersRef, userId), params);
  }
}
