<template>
    <div>
        <v-sheet v-if="tranches.length"
                class="mb-1"
                width="100%"
                height="auto"
                elevation="0"
                color="#F8F8F8">
            <div class="pt-3 pb-3 pl-2 pr-2">
                <v-row no-gutters align="center">
                    <v-icon size="20" class="mr-2">mdi-information</v-icon>
                    <h3 class="card-heading">Información</h3>
                </v-row>
                <div class="card-terms">
                    Asegurate de completar bien los campos de la oferta, ya que si no, no funcionará.
                    <br />
                    <br />
                    Si el HASTA es infinito lo puedes dejar sin completar.
                    <br /><i>Ej: Desde 10€ a Infinito el comercio paga el 100%.</i>
                </div>
            </div>
        </v-sheet>
        <v-container v-for="(tranche, trancheIndex) in tranches">
            <v-row no-gutters align="center">
                <v-col cols="10">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div style="margin-right: 2px;">
                                <v-text-field
                                        label="Desde"
                                        outlined
                                        append-icon="mdi-currency-eur"
                                        type="number"
                                        v-model="tranche.from"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    label="Hasta"
                                    outlined
                                    append-icon="mdi-currency-eur"
                                    type="number"
                                    v-model="tranche.to"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <div>
                        <v-text-field
                                label="Porcentaje que paga el comercio"
                                outlined
                                append-icon="mdi-percent"
                                type="number"
                                v-model="tranche.percentage"
                                min="0"
                                max="100"
                        ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="2">
                    <div>
                        <v-btn @click="removeTranche(trancheIndex)" :ripple="false" icon color="red">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <hr style="margin: 0;"/>
        </v-container>
        <v-btn block :ripple="false" outlined @click="addTranche()" color="primary">
            <v-icon>mdi-plus</v-icon>
            Añadir oferta de envío
        </v-btn>
    </div>
</template>

<style>
    .card-badge {
        display: inline-block;
        margin-bottom: 6px;
        padding: .3em 0.6em;
        background: #eb5e2d;
        color: #FFF;
        font-weight: 400;
        font-size: 12px;
        border-radius: 4px;
    }

    .card-heading {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    .card-subheading {
        font-size: 14px;
        font-weight: 400;
    }

    .card-terms {
        margin: 12px 0 4px 0;
        font-size: 14px;
    }
</style>

<script>

  function tryParseInt(str,defaultValue) {
    var retValue = defaultValue;
    if(str !== null) {
      if(str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }

  function tryParseFloat(str,defaultValue) {
    var retValue = defaultValue;
    if(str !== null) {
      if(str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseFloat(str);
        }
      } else {
        retValue = str;
      }
    }
    return retValue;
  }

    export default {
        props: ['initialTranches'],
        name: 'PaymentTranches',
        mounted() {
          this.tranches = this.initialTranches;
        },
        data: function() {
          return {
            tranches: []
          };
        },
      watch: {
          tranches: {
            deep: true,
            handler(value) {
              this.$emit('input', value);
            }
          }
      },
      methods: {
        addTranche() {
          this.tranches.push({
            from: null,
            to: null,
            percentage: null,
          });
        },
        removeTranche(trancheIndex) {
          this.tranches.splice(trancheIndex, 1);
        },
      }
    }
</script>