import Vue from "vue";
import Router from "vue-router";
import RegisterForm from "./views/RegisterForm";
import firebaseApp from "../core/infra/provider";
import EditForm from "./views/EditForm";
import AddProductForm from "./views/AddProductForm";
import Control from "./views/Control";
import Invoicement from "./views/Invoicement";
import GoveoStoreRepository from "../core/infra/goveoStoreRepository";
import GoveoUserRepository from "../core/infra/goveoUserRepository";
import fetchStore from "../core/app/fetchStore";
import fetchUser from "../core/app/fetchUser";
import { authRedirect } from "./authRedirect";
import PublisherRegisterForm from "@/views/publisher/PublisherRegisterForm.vue";
import PublisherEditForm from "@/views/publisher/PublisherEditForm.vue";
import { getAuth, signInWithCustomToken } from "firebase/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "register-form",
      component: RegisterForm,
      meta: {
        requiresAuth: true,
        title: "Alta de Comercio"
      }
    },
    {
      path: "/publisher/register",
      name: "PublisherRegisterForm",
      component: PublisherRegisterForm,
      meta: {
        requiresAuth: true,
        title: "Alta de Publisher"
      }
    },
    {
      path: "/publisher/manage",
      name: "PublisherRegisterForm",
      component: PublisherEditForm,
      meta: {
        requiresAuth: true,
        title: "Editar perfil"
      }
    },
    {
      path: "/edit",
      name: "edit-form",
      component: EditForm,
      meta: {
        requiresAuth: true,
        title: "Configuración de Comercio"
      }
    },
    {
      path: "/addproduct",
      name: "addproduct-form",
      component: AddProductForm,
      meta: {
        requiresAuth: true,
        title: "Añadir producto"
      }
    },
    {
      path: "/invoices",
      name: "invoices",
      component: Invoicement,
      meta: {
        requiresAuth: true,
        title: "Facturas de Comercio"
      }
    },
    {
      path: "/control",
      name: "control",
      component: Control,
      meta: {
        requiresAuth: true,
        title: "Toma de control de Comercio"
      }
    }
  ]
});

const storesRepository = new GoveoStoreRepository();
const usersRepository = new GoveoUserRepository();

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!to.query.token) {
      console.log("NO TOKEN")

      if (to.path.includes("/edit") || to.path.includes("/publisher/edit")) {
        window.location.assign(
          authRedirect(
            Vue.prototype.$envConfig.goveoIdLogin,
            window.location.href
          )
        );
      } else {
        window.location.assign(
          authRedirect(
            Vue.prototype.$envConfig.goveoIdSignup,
            window.location.href
          )
        );
      }
      return;
    }
    let user;
    let firebaseUser;
    try {
      firebaseUser = await signInWithCustomToken(getAuth(firebaseApp), to.query.token);
      user = await fetchUser(usersRepository, firebaseUser.user.email);
    } catch (e) {
      console.log("Error");
      console.log(e);
      // if (to.path.includes("/edit")) {
      //   window.location.assign(
      //     authRedirect(
      //       Vue.prototype.$envConfig.goveoIdLogin,
      //       window.location.href
      //     )
      //   );
      // } else {
      //   window.location.assign(
      //     authRedirect(
      //       Vue.prototype.$envConfig.goveoIdSignup,
      //       window.location.href
      //     )
      //   );
      // }
      // return;
    }

    if (to.path.includes("/edit") || to.path.includes("/invoices")) {
      const store = await fetchStore(storesRepository, to.query.store);
      if (!user.managerOf.includes(store.id) && user.id !== store.creator) {
        const permissionError =
          "You don't have permission to manage this store.";
        console.error(permissionError);
        throw new Error(permissionError);
      }
      Vue.prototype.$store = store;
    }

    if (to.path.includes("/publisher/manage")) {
      Vue.prototype.$publisher = user;
    }

    Vue.prototype.$token = to.query.token;
    Vue.prototype.$firebaseUser = firebaseUser;
    Vue.prototype.$user = user;
    Vue.prototype.$step = to.query.step;
    Vue.prototype.$atOrigin = to.query.atOrigin;
    Vue.prototype.$fromWeb = to.query.fromWeb;
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title;
  });
});

export default router;
