<template>
  <v-app>
    <div class="form-container">
      <div class="column centered">
        <image-uploader
          @onChange="onAvatarChanged"
          @onOpenCrop="onOpenCrop"
          @onCloseCrop="onCloseCrop"
          :placeholder="
            publisher.avatar ||
              'https://res.cloudinary.com/goveo/image/upload/v1683034379/avatar-placeholder_c4ak8n.svg'
          "
          :cropper-options="cropperOptions"
          :aspect-ratio="avatarAspectRatio"
        >
          <template v-slot:imgbox="slotProps">
            <div class="img-box">
              <v-avatar height="100" width="100">
                <img :src="slotProps.choosedImage || slotProps.placeholder" />
              </v-avatar>
            </div>
          </template>
        </image-uploader>
      </div>
      <v-text-field
        v-model="publisher.username"
        label="Nombre de Usuario"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="publisher.name"
        label="Nombre completo"
        class="required"
        required
      ></v-text-field>
      <v-textarea
        v-model="publisher.bio"
        auto-grow
        clearable
        rows="3"
        label="Bio"
      ></v-textarea>
      <v-text-field
          v-model="publisher.instagram"
          label="Instagram"
          placeholder="Usuario o enlace del perfil"
      ></v-text-field>
      <v-text-field v-model="publisher.link" label="Enlace Web"></v-text-field>
      <v-btn
        block
        :disabled="!validForm"
        :loading="submitting"
        color="primary"
        @click="onSubmit"
        >Guardar</v-btn
      >
    </div>
  </v-app>
</template>

<script>
import * as firebaseApp from "../../../core/infra/provider";
import ImageUploader from "@/components/image-uploader/ImageUploader.vue";
import { ApiClient } from "@/api-client/apiClient";
import editPublisher from "../../../core/app/editPublisher";
import UserRepository from "../../../core/infra/userRepository";
const usersRepository = new UserRepository();

export default {
  name: "PublisherEditForm",
  data() {
    return {
      submitting: false,
      isWebView: !this.$fromWeb,
      user: {},
      apiClient: null,
      registeredDialog: false,
      publisher: {
        username: this.$publisher ? this.$publisher.username || "" : "",
        name: this.$publisher ? this.$publisher.name || "" : "",
        bio: this.$publisher ? this.$publisher.bio || null : null,
        link: this.$publisher ? this.$publisher.link || null : null,
        instagram: this.$publisher ? this.$publisher.instagram || null : null,
        avatar: this.$publisher ? this.$publisher.avatar || null : null
      },
      avatarAspectRatio: 1 / 1,
      cropperOptions: {
        movable: true,
        scalable: false,
        zoomable: true
      }
    };
  },
  beforeMount: async function() {
    this.user = firebaseApp.auth().currentUser;
    const idToken = await this.user.getIdToken();
    this.apiClient = new ApiClient(this.$envConfig.api, idToken);
  },
  components: {
    ImageUploader,
  },
  computed: {
    validForm() {
      return (
          this.publisher.username && this.publisher.name && this.publisher.instagram
      );
    },
  },
  methods: {
    onAvatarChanged(image) {
      this.publisher.avatar = image;
    },
    onOpenCrop() {
      if (!this.$fromWeb) {
        Publisher.postMessage("close_navbar");
      }
    },
    onCloseCrop() {
      if (!this.$fromWeb) {
        Publisher.postMessage("open_navbar");
      }
    },
    async onSubmit() {
      this.submitting = true;

      try {
        let publisher = {
          name: this.publisher.name,
          username: this.publisher.username
        };

        if (this.publisher.avatar) {
          publisher.avatar = this.publisher.avatar;
        }

        if (this.publisher.bio) {
          publisher.bio = this.publisher.bio;
        }

        if (this.publisher.link) {
          publisher.link = this.publisher.link;
        }

        if (this.publisher.instagram) {
          publisher.instagram = this.publisher.instagram;
        }

        await editPublisher(publisher, this.user.uid, {
          userRepository: usersRepository
        });

        if (!this.$fromWeb) {
          Publisher.postMessage("edited");
        } else {
          this.$notify("El perfil se editó correctamente", "success");
        }
      } catch (e) {
        console.log(e);
        this.$notify(
          "Hubo un problema al editar el perfil. Pruebe de nuevo más tarde.",
          "error"
        );
      }

      this.submitting = false;
    },
    validForm() {
      return this.publisher.username && this.publisher.name;
    },
    closeAndReturnToGoveo() {
      this.registeredDialog = false;
      window.location.href = "https://goveo.app";
    }
  }
};
</script>

<style lang="scss">
.column {
  display: flex;
  flex-direction: column;
  &.centered {
    align-items: center;
  }
}
</style>
