<template>
  <div>
    <v-stepper-step
      :editable="editable"
      :complete="currentStep > stepNumber"
      :step="stepNumber"
    >
      Categorías del comercio
    </v-stepper-step>
    <v-stepper-content :step="stepNumber">
        <template v-if="defaultSubCategories.length">
            <div>
                <div class="font-weight-medium">Categorías por defecto:</div>
                <div class="font-weight-regular">(Seleccionar para activar)</div>
            </div>
            <div>
                <v-chip-group
                        v-model="selectedDefaultSubCategoriesTags"
                        column
                        multiple
                        active-class="primary--text"
                >
                    <v-chip v-for="(tag, index) in defaultSubCategoriesTags" :key="index">
                        {{ tag }}
                    </v-chip>
                </v-chip-group>
            </div>
            <br />
        </template>
      <div>
        <vue-tags-input
          v-model="customTag"
          :tags="customSubCategoriesTags"
          placeholder="+ Añadir categoría"
          @tags-changed="newTags => (customSubCategoriesTags = newTags)"
        >
        </vue-tags-input>
      </div>
      <br />
      <v-btn
        block
        :disabled="!valid"
        :loading="submitting"
        color="primary"
        @click="next"
        >{{ submitText }}</v-btn
      >
      <v-btn v-if="hasBack" text @click="back">Volver atrás</v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import { nanoid } from "nanoid";
import GoveoDefaultSubCategoryRepository from "../../../core/infra/goveoDefaultSubCategoryRepository";
import fetchDefaultSubCategories from "../../../core/app/fetchDefaultSubCategories";

const subCategoriesRepository = new GoveoDefaultSubCategoryRepository();

export default {
  props: [
    "stepNumber",
    "currentStep",
    "editable",
    "submitText",
    "hasBack",
    "category",
    "preselectedSubCategories",
    "submitting"
  ],
  beforeMount: async function() {
    await this.populateDefaultSubCategories();
    await this.selectDefaultSubCategories();
    await this.selectCustomSubCategories();
  },
  data: function() {
    return {
      defaultSubCategories: [],
      defaultSubCategoriesTags: {},
      selectedDefaultSubCategoriesTags: [],
      customTag: "",
      customSubCategoriesTags: []
    };
  },
  computed: {
    valid() {
      return this.selectedChip !== null;
    },
    selectedSubCategories() {
      let selectedSubCategories = this.selectedDefaultSubCategoriesTags;

      selectedSubCategories = selectedSubCategories.map(subCategory => {
        return this.defaultSubCategories[subCategory];
      });

      for (let i = 0; i < this.customSubCategoriesTags.length; i++) {
        let customSubCategory = this.customSubCategoriesTags[i];
        let subCategory = {
          name: customSubCategory.text,
          custom: true
        };

        subCategory.id = customSubCategory.id ? customSubCategory.id : nanoid();

        selectedSubCategories.push(subCategory);
      }

      return selectedSubCategories;
    }
  },
  watch: {
    selectedSubCategories(newSelectedSubCategories) {
      if (newSelectedSubCategories) {
        this.$emit("onSelectedSubCategories", newSelectedSubCategories);
      }
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next");
    },
    async populateDefaultSubCategories() {
      this.defaultSubCategories = await fetchDefaultSubCategories(
        this.category,
        {
          goveoDefaultSubCategoryRepository: subCategoriesRepository
        }
      );
      const parsedSubCategories = {};
      this.defaultSubCategories.forEach(category => {
        parsedSubCategories[`${category.id}`] = category.name;
      });

      this.defaultSubCategoriesTags = parsedSubCategories;
    },
    selectDefaultSubCategories() {
      for (let i = 0; i < this.preselectedSubCategories.length; i++) {
        const defaultSelectedSubCategory = {...this.preselectedSubCategories[i]};
        if (!defaultSelectedSubCategory.custom) {
          this.selectedDefaultSubCategoriesTags.push(i);
        }
      }
    },
    selectCustomSubCategories() {
      for (let customSelectedSubCategory of this.preselectedSubCategories) {
        if (customSelectedSubCategory.custom) {
          this.customSubCategoriesTags.push({
            ...customSelectedSubCategory,
            text: customSelectedSubCategory.name,
            tiClasses: ["ti-valid"]
          });
        }
      }
    }
  },
  components: {
    VueTagsInput
  }
};
</script>

<style lang="css">
/* style the background and the text color of the input ... */
.vue-tags-input {
    background: #eb5e2d;
}

.vue-tags-input .ti-new-tag-input {
    background: transparent;
    color: #212121;
}

.vue-tags-input .ti-input {
    padding: 4px 10px;
    border: 0;
    border-bottom: 1px solid #212121;
    font-size: 1em;
    transition: border-bottom 200ms ease;
    padding-left: 0;
    padding-right: 0;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
    border: 0;
    border-bottom: 1px solid #eb5e2d;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
    background: rgba(235, 94, 45, 0.12);
    color: #FFFFFF;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
    color: #212121;
}

.vue-tags-input ::-moz-placeholder {
    color: #212121;
}

.vue-tags-input :-ms-input-placeholder {
    color: #212121;
}

.vue-tags-input :-moz-placeholder {
    color: #212121;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
    position: relative;
    background: rgba(235, 94, 45, 0.12);
    color: #EB5E2D;
    padding: 0 12px;
    height: 32px;
    border-radius: 16px;
}

.vue-tags-input .ti-tags {
    padding-left: 0;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
    text-decoration: line-through;
}

.vue-tags-input .ti-deletion-mark:after {
    transform: scaleX(1);
}
</style>
