import Vue from "vue";
import App from "./App.vue";
import Loader from "./Loader.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import SlimCropper from "vue-slim-cropper";
import * as VueGoogleMaps from "vue2-google-maps";
import firebaseApp from "../core/infra/provider";

import { getAuth } from "firebase/auth";

import "alertifyjs/build/alertify.min.js";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/default.min.css";
import Alertifyjs from "vue2-alertifyjs";
import envConfig from "./envConfig.js";

Vue.prototype.$envConfig = envConfig;

const opts = {
  notifier: {
    delay: 4,
    position: "top-center",
    closeButton: true
  }
};

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB0JQtjGBf7nRqRaPVnVXXCxHP5lbWiof0",
    libraries: "places",
    region: "ES",
    language: "es"
  }
});

Vue.use(SlimCropper);
Vue.use(Alertifyjs, opts);

Vue.config.productionTip = false;

// import the library
import VStripeElements from "@dprimenko/v-stripe-elements/lib";
// and register it
Vue.use(VStripeElements);

new Vue({
  router,
  vuetify,
  render: h => h(Loader)
}).$mount("#app");

getAuth(firebaseApp).onAuthStateChanged(function() {
  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
});
