<template>
  <div>
    <v-stepper-step
      :editable="editable"
      :complete="currentStep > stepNumber"
      :step="stepNumber"
    >
      Dirección de la tienda
    </v-stepper-step>
    <v-stepper-content :step="stepNumber">
      <div>
        <input
          v-if="isWebview"
          class="input goveo-input"
          :value="goveoStore.address.formattedAddress"
          placeholder="Introduzca la dirección del comercio"
          readonly
          @click="openWebviewAutocomplete"
        />
        <div v-else>
          <address-autocomplete
            v-model="selected"
            :restrict-to-countries="['es']"
            :placeholder="goveoStore.address.formattedAddress ? goveoStore.address.formattedAddress : 'Introducir dirección del comercio'"
            noResultsFoundMessage="No se encontró la dirección"
          ></address-autocomplete>
        </div>
        <div v-if="isWebview">
          <br />
        </div>
        <gmap-map
          ref="mapRef"
          :center="center"
          :zoom="zoom"
          :options="mapOptions"
          style="width:100%;  height: 600px;"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :draggable="true"
            @click="center = m.position"
            @dragend="markerChanged"
          ></gmap-marker>
        </gmap-map>
      </div>
      <br />
      <v-btn
        block
        :disabled="!valid"
        :loading="submitting"
        color="primary"
        @click="next"
        >{{ submitText }}
      </v-btn>
      <v-btn v-if="hasBack" text @click="back">Volver atrás</v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
import ngeohash from "ngeohash";
import AddressAutocomplete from "../address-autocomplete/AddressAutocomplete.vue";

export default {
  components: {
    AddressAutocomplete
  },
  props: [
    "stepNumber",
    "currentStep",
    "goveoStore",
    "editable",
    "submitText",
    "hasBack",
    "submitting",
    "isWebview"
  ],
  data: function() {
    return {
      valid: false,
      center: { lat: 40.416973, lng: -3.7063513 },
      mapsLoaded: false,
      zoom: 15,
      markers: [],
      selected: null,
      mapOptions: {
        mapTypeControl: false
      }
    };
  },
  mounted() {
    if (this.isWebview) {
      window.addEventListener("autocomplete_closed", e => {
        this.setPlaceWebview(e.selectedAddr);
      });
    }

    if (this.goveoStore.address.position) {
      this.preselectInMap();
    } else {
      this.geolocate();
    }
  },
  watch: {
    selected(value) {
      this.setPlace(value);
    }
  },
  methods: {
    openWebviewAutocomplete() {
      Store.postMessage("open_autocomplete");
    },
    markerChanged(markerPos) {
      const formattedPlace = {
        position: {
          latitude: markerPos.latLng.lat(),
          longitude: markerPos.latLng.lng()
        },
        geoHash: ngeohash.encode(
          markerPos.latLng.lat(),
          markerPos.latLng.lng(),
          9
        ),
        formattedAddress: this.goveoStore.address.formattedAddress
      };
      this.valid = true;
      this.$emit("onAddressSelect", formattedPlace);
    },
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next");
    },
    setPlace(place) {
      const formattedPlace = {
        position: {
          latitude: place.location.lat,
          longitude: place.location.lng
        },
        geoHash: ngeohash.encode(
          place.location.lat,
          place.location.lng,
          9
        ),
        formattedAddress: place.address
      };
      this.valid = true;
      this.$emit("onAddressSelect", formattedPlace);
      this.addMarker(formattedPlace.position);
    },
    setPlaceWebview(place) {
      const formattedPlace = {
        position: {
          latitude: place.lat,
          longitude: place.lng
        },
        geoHash: ngeohash.encode(place.lat, place.lng, 9),
        formattedAddress: place.formattedAddress
      };
      this.valid = true;
      this.$emit("onAddressSelect", formattedPlace);
      this.addMarker(formattedPlace.position);
    },
    addMarker(place) {
      if (place) {
        const marker = {
          lat: place.latitude,
          lng: place.longitude
        };
        this.markers = [];
        this.markers.push({ position: marker });
        this.center = marker;
        this.zoom = 17;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    preselectInMap() {
      this.center = {
        lat: this.goveoStore.address.position.latitude,
        lng: this.goveoStore.address.position.longitude
      };
      this.formattedAddress = this.goveoStore.address.formattedAddress;
      const marker = {
        lat: this.goveoStore.address.position.latitude,
        lng: this.goveoStore.address.position.longitude
      };
      this.markers = [];
      this.markers.push({ position: marker });
      this.center = marker;
      this.zoom = 17;
    }
  }
};
</script>

<style lang="scss">
.goveo-input:active,
.goveo-input:focus {
  border-color: #eb5e2d;
}
</style>
