<template>
    <div>
        <v-stepper-step
                :editable="editable"
                :complete="currentStep > stepNumber"
                :step="stepNumber">
            Horario del comercio
        </v-stepper-step>
        <v-stepper-content :step="stepNumber">
            <schedule-form :current-schedule="goveoStore.schedule" @scheduleChanged="onScheduleChanged"></schedule-form>
            <div class="mb-3"></div>

            <v-btn
                    block
                    :loading="submitting"
                    color="primary"
                    @click="next">{{ submitText }}</v-btn
            >
        </v-stepper-content>
    </div>
</template>

<script>
  import ScheduleForm from '../schedule-form/ScheduleForm.vue';

  export default {
    components: {
      ScheduleForm
    },
    props: [
      "stepNumber",
      "currentStep",
      "goveoStore",
      "validated",
      "editable",
      "submitText",
      "submitting"
    ],
    methods: {
      next() {
        this.$emit("next");
      },
      onScheduleChanged(schedule) {
        this.$emit("scheduleChanged", schedule);
      }
    }
  };
</script>

<style lang="scss">
    .required label::after {
        content: "*";
        color: red;
    }
    .content-middle {
        vertical-align: middle;
    }
</style>
