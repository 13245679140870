import { collection, query, getFirestore, getDocs } from "firebase/firestore";
import firebaseApp from './provider';
import { create } from '../domain/category';

const goveoFirestore = getFirestore(firebaseApp);
const goveoCategoriesRef = collection(goveoFirestore, "categories");

export default class GoveoCategoryRepository {
    async fetch() {
        const q = query(goveoCategoriesRef);
        let categories = await getDocs(q);
        return categories.docs.map((doc) => {
            return create(doc.data());
        });
    }
}
