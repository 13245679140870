import Vue from "vue";
import axios from "axios";

export function useStoreControl() {
  const getStore = async atOrigin => {
    const result = await axios.post(
      `${Vue.prototype.$envConfig.firebaseFunctionsHost}/getStoreByToken`,
      {
        token: atOrigin
      }
    );
    return result.data;
  };

  const takeStoreControl = async (atOrigin, userToken) => {
    await axios.post(
      `${Vue.prototype.$envConfig.firebaseFunctionsHost}/takeStoreControl`,
      {
        token: atOrigin,
        userToken: userToken
      }
    );
  };

  const notNow = async (atOrigin, userToken) => {
    await axios.post(
      `${Vue.prototype.$envConfig.firebaseFunctionsHost}/notNowControl`,
      {
        token: atOrigin,
        userToken: userToken,
      }
    );
  };

  return {
    getStore,
    takeStoreControl,
    notNow
  };
}
