<template>
  <v-app>
    <div class="form-container">
      <div class="column centered">
        <image-uploader
          @onChange="onAvatarChanged"
          @onOpenCrop="onOpenCrop"
          @onCloseCrop="onCloseCrop"
          :placeholder="
            publisher.avatar ||
              'https://res.cloudinary.com/goveo/image/upload/v1683034379/avatar-placeholder_c4ak8n.svg'
          "
          :cropper-options="cropperOptions"
          :aspect-ratio="avatarAspectRatio"
        >
          <template v-slot:imgbox="slotProps">
            <div class="img-box">
              <v-img
                :src="slotProps.choosedImage || slotProps.placeholder"
                contain
                width="100"
                max-width="100"
              ></v-img>
            </div>
          </template>
        </image-uploader>
      </div>
      <v-text-field
        v-model="publisher.username"
        label="Nombre de Usuario"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="publisher.name"
        label="Nombre completo"
        class="required"
        required
      ></v-text-field>
      <v-text-field
        v-model="publisher.instagram"
        label="Instagram"
        placeholder="Usuario o enlace del perfil"
        class="required"
        required
      ></v-text-field>
      <v-textarea
        v-model="publisher.bio"
        auto-grow
        clearable
        rows="3"
        label="Bio"
      ></v-textarea>
      <v-text-field v-model="publisher.link" label="Enlace Web"></v-text-field>
      <div>
        <v-checkbox
          v-model="termsAccepted"
          color="primary"
          style="margin-left: 8px;"
        >
          <template v-slot:label>
            <div>
              Afirma haber leído las
              <a @click.stop="launchConditionsFromApp()" v-if="!$fromWeb">
                Condiciones de Uso
              </a>
              <a
                v-else
                target="_blank"
                href="https://goveo.app/conditions-publishers"
                @click.stop
              >
                Condiciones de Uso
              </a>
              para publishers.
            </div>
          </template>
        </v-checkbox>
      </div>
      <v-btn
        block
        :disabled="!validForm"
        :loading="submitting"
        color="primary"
        @click="onSubmit"
        >Registrar</v-btn
      >
      <RegisteredPublisherDialog
        :opened="registeredDialog"
        @closed="closeAndReturnToGoveo"
      ></RegisteredPublisherDialog>
    </div>
  </v-app>
</template>

<script>
import { getAuth } from "firebase/auth";

import ImageUploader from "@/components/image-uploader/ImageUploader.vue";
import RegisteredPublisherDialog from "@/components/registered-dialog/RegisteredPublisherDialog.vue";
import { ApiClient } from "@/api-client/apiClient";
import createPublisher from "../../../core/app/createPublisher";
import isPublisher from "../../../core/app/isPublisher";
import UserRepository from "../../../core/infra/userRepository";
const usersRepository = new UserRepository();

export default {
  name: "PublisherRegisterForm",
  data() {
    return {
      submitting: false,
      isWebView: !this.$fromWeb,
      user: {},
      apiClient: null,
      registeredDialog: false,
      termsAccepted: false,
      publisher: {
        username: "",
        name: "",
        bio: null,
        link: null,
        avatar: null,
        instagram: null
      },
      avatarAspectRatio: 1,
      cropperOptions: {
        movable: true,
        scalable: false,
        zoomable: true
      }
    };
  },
  beforeMount: async function() {
    this.user = getAuth().currentUser;
    const idToken = await this.user.getIdToken();
    this.apiClient = new ApiClient(this.$envConfig.api, idToken);
  },
  components: {
    ImageUploader,
    RegisteredPublisherDialog
  },
  computed: {
    validForm() {
      return (
          this.publisher.username && this.publisher.name && this.publisher.avatar && this.publisher.instagram && this.termsAccepted
      );
    },
  },
  methods: {
    onAvatarChanged(image) {
      this.publisher.avatar = image;
    },
    onOpenCrop() {
      if (!this.$fromWeb) {
        Publisher.postMessage("close_navbar");
      }
    },
    onCloseCrop() {
      if (!this.$fromWeb) {
        Publisher.postMessage("open_navbar");
      }
    },
    async onSubmit() {
      this.submitting = true;

      try {
        if (!this.termsAccepted) {
          this.$notify("Debe aceptar las condiciones de uso.", "error");
          return;
        }

        if (!this.publisher.avatar) {
          this.$notify("La imagen de avatar es obligatoria.", "error");
          return;
        }

        let publisher = {
          name: this.publisher.name,
          username: this.publisher.username,
          avatar: this.publisher.avatar
        };

        if (this.publisher.bio) {
          publisher.bio = this.publisher.bio;
        }

        if (this.publisher.link) {
          publisher.link = this.publisher.link;
        }

        if (this.publisher.instagram) {
          publisher.instagram = this.publisher.instagram;
        }

        console.log("USER");
        console.log(this.user.uid);

        const alreadyExists = await isPublisher(this.user.uid, {
          userRepository: usersRepository
        });

        if (alreadyExists) {
          this.$notify(
            "Este usuario ya está registrado como publisher",
            "error"
          );
        } else {
          await createPublisher(publisher, this.user.uid, {
            userRepository: usersRepository
          });

          if (!this.$fromWeb) {
            Publisher.postMessage("success");
          } else {
            this.registeredDialog = true;
          }
        }
      } catch (e) {
        console.log(e);
        this.$notify(
          "Hubo un problema al registrarte como Publisher. Pruebe de nuevo más tarde.",
          "error"
        );
      }

      this.submitting = false;
    },
    launchConditionsFromApp(e) {
      if (e) e.preventDefault();
      Publisher.postMessage(
        "launchlink_https://es.goveo.app/condiciones-publishers"
      );
    },
    closeAndReturnToGoveo() {
      this.registeredDialog = false;
      window.location.href = "https://goveo.app";
    }
  }
};
</script>

<style lang="scss">
.column {
  display: flex;
  flex-direction: column;
  &.centered {
    align-items: center;
  }
}
</style>
