<template>
    <div>
        <v-stepper-step :editable="editable" :complete="currentStep > stepNumber" :step="stepNumber">
            Datos básicos de la tienda
            <small>(nombre, descripción, sitio web, etc.)</small>
        </v-stepper-step>
        <v-stepper-content :step="stepNumber">
            <template v-if="isRegistration">
                <v-sheet
                        class="mb-1"
                        v-if="selectedPlan"
                        width="100%"
                        height="auto"
                        elevation="0"
                        color="#F8F8F8">
                    <div class="pt-3 pb-3 pl-2 pr-2">
                        <span class="card-badge" v-if="goveoStore.coupon && !goveoStore.coupon.noChanges">Promoción {{goveoStore.coupon.name}}</span>
                        <h3 class="card-heading">{{selectedPlan.name}}</h3>
                        <h4 class="card-subheading">
                            <p v-if="!goveoStore.coupon || goveoStore.coupon.noChanges"><strong>{{amountWithTax.toFixed(2)}} €</strong> / al mes (+ IVA)</p>
                            <p v-else><strong>{{(goveoStore.coupon.before) ? (amountWithTax - (amountWithTax * (goveoStore.coupon.percentOff / 100))).toFixed(2) : amountWithTax.toFixed(2)}} €</strong> / {{ goveoStore.coupon.description }} {{ (goveoStore.coupon.before) ? amountWithTax.toFixed(2) : ""}} (+ IVA)</p>
                            <div class="plan-description" v-if="selectedPlan.description">
                                <div
                                        class="mb-2"
                                        v-for="description in selectedPlan.description"
                                >
                                    <span>{{ description }}</span>
                                </div>
                            </div>
                        </h4>
                    </div>
                </v-sheet>
                <v-expansion-panels

                        multiple>
                    <v-expansion-panel class="no-shadow">
                        <v-expansion-panel-header style="background-color: #F8F8F8;">¿TIENES UN CÓDIGO?</v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: #F8F8F8;">
                            <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
                                <v-col cols="12" sm="8">
                                    <v-text-field
                                            label="Introduzca su código"
                                            outlined
                                            v-model="insertedCoupon"
                                            :success="goveoStore.coupon && this.validatedCoupon"
                                            :success-messages="(goveoStore.coupon && this.validatedCoupon) ? 'El cupon es válido' : ''"
                                            :error="!goveoStore.coupon && this.validatedCoupon"
                                            :error-messages="(!goveoStore.coupon && this.validatedCoupon) ? 'El cupon es inválido' : ''">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn color="primary" min-height="40" width="100%" :loading="validatingCoupon" @click="validateCoupon">Aplicar código</v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
            <v-form v-model="valid" ref="basicDataForm" autocomplete="off">
                <v-text-field
                        v-model="goveoStore.name"
                        label="Nombre de la tienda"
                        class="required"
                        :rules="nameRules"
                        autocapitalize="word"
                        required
                ></v-text-field>
                <v-text-field
                        v-model="goveoStore.email" type="email"
                        label="Email"
                        class="required"
                        :rules="emailRules"
                        autocapitalize="off"
                        required
                ></v-text-field>
                <v-select
                        v-model="goveoStore.prefixPhoneNumber"
                        :items="prefixes"
                        label="Prefijo"
                        prefix="+"
                ></v-select>
                <v-text-field
                        v-model="goveoStore.phoneNumber"
                        :rules="phoneNumberRules"
                        label="Télefono"
                        class="required"
                        autocapitalize="off"
                        required
                ></v-text-field>
                <v-switch
                        color="primary"
                        v-model="goveoStore.isAppointment"
                        label="Activar Contacto"
                ></v-switch>
                <v-switch
                        color="primary"
                        v-model="goveoStore.isWhatsapp"
                        label="¿Permitir al usuario contactar por Whatsapp?"
                ></v-switch>
                <v-text-field
                        autocapitalize="off"
                        v-model="goveoStore.website"
                        label="Sitio web"
                ></v-text-field>
                <v-textarea auto-grow clearable rows="3" label="Descripción" v-model="goveoStore.description"></v-textarea>
                <v-btn block :disabled="!valid" :loading="submitting" color="primary" @click="next">{{submitText}}</v-btn>
            </v-form>
        </v-stepper-content>
    </div>
</template>

<script>
  export default {
    props: ["stepNumber", "currentStep", "isRegistration", "validatingCoupon", "validatedCoupon", "selectedPlan", "goveoStore", "validated", "editable", "submitText", "submitting"],
    data: function() {
      return {
        valid: false,
        prefixes: [
          34
        ],
        nameRules: [
          v => !!v || "El nombre es obligatorio"
        ],
        emailRules: [
          v => !!v || "El email es obligatorio",
          v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.trim()) || "El email es inválido"
        ],
        phoneNumberRules: [
          v => !!v || "El número de teléfono es obligatorio",
          v => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(v) || "El número de teléfono es inválido"
        ],
        insertedCoupon: "",
      };
    },
    watch: {
      insertedCoupon(value) {
        this.$emit("insertedCoupon", value);
      }
    },
    computed: {
      amountWithTax() {
        return this.selectedPlan.amount;
      },
    },
    methods: {
      next() {
        const validation = this.validate();
        if (validation) {
          this.$emit("onValidated", validation);
          this.$emit("next");
        }
      },
      validate() {
        return this.$refs.basicDataForm.validate();
      },
      validateCoupon() {
        this.$emit("validateCoupon");
      },
    }
  };
</script>

<style lang="scss">
    .required label::after {
        content: "*";
        color: red;
    }
    .no-shadow::before {
        box-shadow: initial !important;
    }
</style>
