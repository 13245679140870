<template>
  <v-app>
    <div class="form-container">
      <div v-if="isPromo" class="promo">
        <div class="pinfo">
          <div class="ptext">PROMOCIÓN AYUDA #VamosJuntosComercio</div>
          <div class="ptext">GRATIS HASTA EL 30 DE ENERO</div>
          <div>
            <div
              :class="{
                'more-info': applicablePromo,
                'call-to-action': !applicablePromo
              }"
              @click="onPromoMoreInfo"
            >
              Más información
            </div>
          </div>
          <div v-if="applicablePromo">
            <div
              :class="{ 'call-to-action': true, csuccess: validatedPromo }"
              @click="onPromo('vamosjuntoscomercio')"
            >
              <template v-if="!validatingPromo">{{
                validatedPromo ? "✔ APLICADO" : "APLICAR CÓDIGO"
              }}</template>
              <template v-else>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="20"
                  :width="2"
                ></v-progress-circular>
              </template>
            </div>
          </div>
        </div>
      </div>
      <v-stepper v-model="currentStep" vertical>
        <basic-data
          :submit-text="submitText"
          :currentStep="currentStep"
          :step-number="1"
          :editable="stepsStatus[1]"
          :is-registration="true"
          @next="onNextStep"
          :goveo-store="goveoStore"
          @validateCoupon="onValidateCoupon"
          @insertedCoupon="onInsertedCoupon"
          :selected-plan="selectedPlan"
          :validated-coupon="validatedCoupon"
          :validating-coupon="validatingCoupon"
          :interval="interval"
          :intervalCount="interval"
        ></basic-data>
        <main-image-step
                :goveo-store="goveoStore"
                :submit-text="submitText"
                :need-validate="true"
                :step-number="2"
                :editable="stepsStatus[2]"
                hasBack
                :currentStep="currentStep"
                @next="onNextStep"
                @back="onBackStep"
                @onMainImageSelect="onMainImageSelected"
                @onAvatarImageSelect="onAvatarSelected"
        ></main-image-step>
        <select-address-step
          :goveo-store="goveoStore"
          :submit-text="submitText"
          :is-webview="isWebView"
          hasBack
          :currentStep="currentStep"
          :step-number="3"
          :editable="stepsStatus[3]"
          @next="onNextStep"
          @back="onBackStep"
          @onAddressSelect="onAddressSelected"
        ></select-address-step>
        <select-categories
          :submitting="loadingPlans"
          :submit-text="submitText"
          hasBack
          :currentStep="currentStep"
          :step-number="4"
          :editable="stepsStatus[4]"
          @next="onNextStep"
          @back="onBackStep"
          @onSelectCategory="onSelectedCategory"
        ></select-categories>
        <select-plan
          v-show="!selectedPlan || !validatedCoupon || goveoStore.coupon.commercial || !goveoStore.coupon.plan"
          :submit-text="submitText"
          hasBack
          :currentStep="currentStep"
          :step-number="5"
          :editable="stepsStatus[5]"
          @back="onBackStep"
          @onSelectPlan="onSelectedPlan"
          @changedInterval="onChangedInterval"
          @next="onNextStep"
          :loading-plans="loadingPlans"
          :goveo-store="goveoStore"
          :plans="plans"
          :interval="interval"
        ></select-plan>
        <billing-data-step
            :currentStep="currentStep"
            :step-number="6"
            :editable="stepsStatus[6]"
            @next="onNextStep"
            @back="onBackStep"
            :goveo-store="goveoStore"
            :submit-text="submitText"
        ></billing-data-step>
        <register-resume
                :submitting="submitting"
                hasBack
                :currentStep="currentStep"
                :step-number="7"
                :editable="stepsStatus[7]"
                @back="onBackStep"
                @onSubmit="onSubmitted"
                @paymentMethodCreated="onPaymentMethodCreated"
                @validateCoupon="onValidateCoupon"
                @validateSocial="onValidateSocial"
                @insertedCoupon="onInsertedCoupon"
                @insertedSocial="onInsertedSocial"
                :goveo-store="goveoStore"
                :selected-plan="selectedPlan"
                :validated-coupon="validatedCoupon"
                :validating-coupon="validatingCoupon"
                :validated-social="validatedSocial"
                :validating-social="validatingSocial"
                :interval="interval"
        ></register-resume>
      </v-stepper>
      <RegisteredDialog
        :opened="registeredDialog"
        @closed="closeAndReturnToGoveo"
      ></RegisteredDialog>
    </div>
  </v-app>
</template>

<script>
import { getAuth } from "firebase/auth";
import firebaseApp from "../../core/infra/provider";
import BasicData from "../components/steps/BasicData.vue";
import SelectCategories from "../components/steps/SelectCategories.vue";
import SelectAddressStep from "../components/steps/SelectAddressStep.vue";
import MainImageStep from "../components/steps/MainImageStep.vue";
import RegisterResume from "../components/steps/RegisterResume.vue";
import { ApiClient } from "@/api-client/apiClient";

import GoveoStoreRepository from "../../core/infra/goveoStoreRepository";
import UserRepository from "../../core/infra/userRepository";
import createGoveoStore from "../../core/app/createGoveoStore";
import SelectPlan from "../components/steps/SelectPlan.vue";
import RegisteredDialog from "../components/registered-dialog/RegisteredDialog";
import BillingDataStep from "@/components/steps/BillingDataStep.vue";

const storesRepository = new GoveoStoreRepository();
const usersRepository = new UserRepository();

export default {
  name: "register-form",
  data() {
    return {
      submitting: false,
      submitText: "Continuar",
      currentStep: parseInt(this.$step) || 1,
      isWebView: !this.$fromWeb,
      user: {},
      apiClient: null,
      loadingPlans: false,
      plans: null,
      selectedPlan: null,
      interval: "month",
      registeredDialog: false,
      goveoStore: {
        name: "",
        email: "",
        prefixPhoneNumber: 34,
        phoneNumber: "",
        website: "",
        description: "",
        address: {},
        isWhatsapp: false,
        isAppointment: false,
        coupon: null,
        socialLinks: null
      },
      insertedCoupon: "",
      insertedSocial: null,
      validatingCoupon: false,
      validatedCoupon: false,
      validatedSocial: false,
      validatingSocial: false,
      isPromo: false,
      applicablePromo: false,
      validatedPromo: false,
      validatingPromo: false,
      stepsStatus: [false, false, false, false, false, false, false]
    };
  },
  beforeMount: async function() {
    this.user = getAuth(firebaseApp).currentUser;
    const idToken = await this.user.getIdToken();
    this.apiClient = new ApiClient(this.$envConfig.api, idToken);
  },
  created() {
    if (!this.$fromWeb) {
      Store.postMessage("initialized");
    }
  },
  watch: {
    interval(value) {
      this.loadPlans();
    }
  },
  methods: {
    onBackStep() {
      this.currentStep -= 1;
    },
    async onNextStep() {
      this.stepsStatus[this.currentStep] = true;
      if (this.currentStep === 4) {
        if (this.selectedPlan && this.validatedCoupon) {
          this.currentStep += 1;
        } else {
          await this.loadPlans();
        }
      }
      this.currentStep += 1;
    },
    onAddressSelected(place) {
      this.goveoStore.address = place;
    },
    onSelectedCategory(selectedCategory) {
      this.goveoStore.category = selectedCategory.id;
      this.goveoStore.categoryType = selectedCategory.type;
    },
    async onSelectedPlan(selectedPlan) {
      this.selectedPlan = selectedPlan;

      if (selectedPlan.coupons) {
        this.insertedCoupon = selectedPlan.coupons[0];
        await this.onValidateCoupon();
      } else {
        this.insertedCoupon = "";
      }
    },
    onChangedInterval(interval) {
      this.interval = interval;
    },
    onPaymentMethodCreated(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    onInsertedCoupon(value) {
      this.insertedCoupon = value;
    },
    onInsertedSocial(value) {
      this.insertedSocial = value;
    },
    onMainImageSelected(mainImage) {
      this.goveoStore.mainImage = mainImage;
    },
    onAvatarSelected(avatar) {
      this.goveoStore.avatar = avatar;
    },
    async onValidateCoupon() {
      this.validatingCoupon = true;
      this.validatedCoupon = false;

      try {
        const coupon = await this.apiClient.get(
          `/subspayment/coupons/${encodeURIComponent(this.insertedCoupon.toLowerCase())}`
        );
        const valid = !!coupon.data.id;
        if (valid) {
          this.goveoStore.coupon = coupon.data;

          if (coupon.data.partner) {
            this.goveoStore.partner = coupon.data.partner;
          }

          if (coupon.data.plan) {
            const specialPlan = await this.apiClient.get(
                    `/subspayment/plan/${coupon.data.plan}`
            );
            this.selectedPlan = specialPlan.data;
          }

          if (coupon.data.commercial) {
            this.interval = "biannual";
          }
        }
        this.validatedCoupon = true;
      } catch (e) {
        this.validatedCoupon = false;
      }

      this.validatingCoupon = false;
    },
    async onValidateSocial() {
      this.validatingSocial = true;
      this.validatedSocial = false;

      this.insertedCoupon = "promocionsocial";
      await this.onValidateCoupon();

      const plan = await this.apiClient.get(
        "/subspayment/plan/cPNGR67qClPokp7a6pDf"
      );

      this.selectedPlan = plan.data;
      this.goveoStore.socialLinks = this.insertedSocial;
      this.validatedSocial = true;
      this.validatingSocial = false;
    },
    async onPromoMoreInfo() {
      if (!this.$fromWeb) {
        Store.postMessage("promo");
      }
    },
    async onPromo(coupon) {
      if (this.validatedPromo) {
        return;
      }

      this.validatingPromo = true;
      this.validatedPromo = false;

      this.insertedCoupon = coupon;
      await this.onValidateCoupon();

      this.validatedPromo = true;
      this.validatingPromo = false;
    },
    async loadPlans() {
      this.loadingPlans = true;
      this.plans = [];
      const rates = await this.apiClient.post(
        "/subspayment/products?full=true",
        {
          type: this.goveoStore.categoryType,
          interval: this.interval
        }
      );
      this.loadingPlans = false;
      this.plans = rates.data[0].plans;
    },
    async createCustomer(paymentMethod) {
      const result = await this.apiClient.post("/subspayment/create-customer", {
        payment_method: paymentMethod.id,
        billingDetails: paymentMethod.billing_details
      });
      return result.data;
    },
    async onSubmitted() {
      this.submitting = true;

      try {
        const store = {
          name: this.goveoStore.name,
          description: this.goveoStore.description,
          address: {
            geoPoint: {
              latitude: this.goveoStore.address.position.latitude,
              longitude: this.goveoStore.address.position.longitude
            },
            geoHash: this.goveoStore.address.geoHash,
            formattedAddress: this.goveoStore.address.formattedAddress
          },
          website: this.goveoStore.website,
          category: this.goveoStore.category,
          isWhatsapp: this.goveoStore.isWhatsapp,
          isAppointment: this.goveoStore.isAppointment,
          prefixPhoneNumber: this.goveoStore.prefixPhoneNumber,
          phoneNumber: this.goveoStore.phoneNumber,
          email: this.goveoStore.email.trim(),
          companyName: this.goveoStore.companyName,
          cif: this.goveoStore.cif,
          billingAddress: this.goveoStore.billingAddress,
          billingEmail: this.goveoStore.billingEmail.trim(),
          billingPhoneNumber: this.goveoStore.billingPhoneNumber,
          partner: this.goveoStore.partner,
          mainImage: this.goveoStore.mainImage,
          avatar: this.goveoStore.avatar,
          paymentData: {
            selectedPlan: (this.goveoStore.coupon && this.goveoStore.coupon.noCheckout) ? {
              ...this.selectedPlan,
              amount: 0
            } : this.selectedPlan
          }
        };

        if (this.paymentMethod) {
          const customer = await this.createCustomer(this.paymentMethod);
          store.paymentData.customer = customer;
        }

        if (this.goveoStore.coupon) {
          store.paymentData.coupons = [this.goveoStore.coupon];
        }

        if (this.goveoStore.socialLinks) {
          store.paymentData.socialLinks = this.goveoStore.socialLinks;
        }

        const createdStore = await createGoveoStore(store, this.user.uid, {
          goveoStoreRepository: storesRepository,
          userRepository: usersRepository
        });
        if (!this.$fromWeb) {
          Store.postMessage("success");
        } else {
          this.registeredDialog = true;
        }
      } catch (e) {
        console.log(e);
        this.$notify(
          "Hubo un problema al crear la tienda. Pruebe de nuevo más tarde.",
          "error"
        );
      }

      this.submitting = false;
    },
    closeAndReturnToGoveo() {
      this.registeredDialog = false;
      window.location.href = "https://goveo.app/tiendas";
    }
  },
  components: {
    BillingDataStep,
    RegisteredDialog,
    SelectPlan,
    BasicData,
    SelectCategories,
    SelectAddressStep,
    MainImageStep,
    RegisterResume
  }
};
</script>

<style>
.promo {
  background-color: #eb5e2d;
  width: 100%;
  padding-top: 6px;
}

.promo .pinfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.promo .pinfo .ptext {
  color: white;
  font-size: 18px;
  text-align: center;
}

.promo .more-info {
  font-size: 16px;
  padding: 5px 16px;
  border-radius: 30px;
  margin-left: 6px;
  color: white;
  transition: 0.3s ease-in-out;
}

.promo .call-to-action {
  font-size: 14px;
  padding: 5px 16px;
  border-radius: 30px;
  margin-left: 6px;
  background-color: white;
  transition: 0.3s ease-in-out;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  width: 146px;
  text-align: center;
}

.promo .call-to-action.csuccess {
  font-size: 14px;
  padding: 5px 16px;
  border-radius: 30px;
  margin-left: 6px;
  color: #fff;
  background-color: #1faa00;
  transition: 0.3s ease-in-out;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
