import {
  collection,
  getFirestore,
  doc,
  arrayUnion,
  updateDoc,
  GeoPoint,
  Timestamp,
  getDoc,
  setDoc
} from "firebase/firestore";
import firebaseApp from './provider';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';

const goveoFirestore = getFirestore(firebaseApp);
const usersRef = collection(goveoFirestore, "users");
const goveoStoresRef = collection(goveoFirestore, "stores");
const goveoStoresToConfirmRef = collection(goveoFirestore, "storestoconfirm");

async function uploadImage(fileName, imageBlob) {
  const urlUpload = `https://api.cloudinary.com/v1_1/goveo/upload`;
  let formData = new FormData();
  formData.append("api_key",process.env.VUE_APP_CLOUDINARY_API_KEY);
  formData.append("file", imageBlob);
  formData.append("public_id", fileName);
  formData.append("upload_preset", process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET);

  const imageResponse = await axios.post(urlUpload, formData);
  return imageResponse.data['secure_url'];
}

export default function() {
  this.add = async (goveoStore, userId) => {
    const goveoStoreId = uuidv4();
    let mainImageUrl;
    let avatarUrl;

    if (
      typeof goveoStore.mainImage !== "string" &&
      typeof goveoStore.mainImage !== "undefined"
    ) {
      mainImageUrl = await uploadImage(`main_${uuidv4()}`, goveoStore.mainImage);
    }

    if (
      typeof goveoStore.avatar !== "string" &&
      typeof goveoStore.avatar !== "undefined"
    ) {
      avatarUrl = await uploadImage(`avatar_${uuidv4()}`, goveoStore.avatar);
    }

    await setDoc(doc(goveoStoresToConfirmRef, goveoStoreId), {
      name: goveoStore.name,
      website: goveoStore.website,
      address: {
        geoHash: goveoStore.address.geoHash,
        geoPoint: new GeoPoint(
          goveoStore.address.geoPoint.latitude,
          goveoStore.address.geoPoint.longitude
        ),
        formattedAddress: goveoStore.address.formattedAddress
      },
      categoryId: goveoStore.category,
      isWhatsapp: goveoStore.isWhatsapp,
      isAppointment: goveoStore.isAppointment,
      prefixPhoneNumber: goveoStore.prefixPhoneNumber,
      phoneNumber: goveoStore.phoneNumber
        ? goveoStore.phoneNumber.replace(/\s/g, "")
        : goveoStore.phoneNumber,
      email: goveoStore.email,
      companyName: goveoStore.companyName,
      cif: goveoStore.cif,
      billingAddress: goveoStore.billingAddress,
      billingEmail: goveoStore.billingEmail,
      billingPhoneNumber: goveoStore.billingPhoneNumber,
      paymentData: goveoStore.paymentData,
      confirmed: false,
      mainImage: mainImageUrl ? mainImageUrl : null,
      avatar: avatarUrl ? avatarUrl : null,
      creator: userId,
      partner: (goveoStore.partner) ? goveoStore.partner : null,
      createdAt: Timestamp.now(),
    });

    return updateDoc(doc(usersRef, userId), {
      managerOf: arrayUnion(goveoStoreId)
    });
  };

  this.edit = async goveoStore => {
    let mainImageUrl;
    let avatarUrl;

    if (
      typeof goveoStore.mainImage !== "string" &&
      typeof goveoStore.mainImage !== "undefined"
    ) {
      mainImageUrl = await uploadImage(`main_${uuidv4()}`, goveoStore.mainImage);
    }

    if (
      typeof goveoStore.avatar !== "string" &&
      typeof goveoStore.avatar !== "undefined"
    ) {
      avatarUrl = await uploadImage(`avatar_${uuidv4()}`, goveoStore.avatar);
    }


    let storeToUpdate = {
      name: goveoStore.name,
      description: goveoStore.description,
      website: goveoStore.website,
      address: {
        geoHash: goveoStore.address.geoHash,
        geoPoint: new GeoPoint(
          goveoStore.address.geoPoint.latitude,
          goveoStore.address.geoPoint.longitude
        ),
        formattedAddress: goveoStore.address.formattedAddress
      },
      establishmentMeasures: parseInt(goveoStore.establishmentMeasures),
      isMall: goveoStore.isMall,
      isBrandedStore: goveoStore.isBrandedStore,
      isWhatsapp: goveoStore.isWhatsapp,
      isAppointment: goveoStore.isAppointment,
      prefixPhoneNumber: goveoStore.prefixPhoneNumber,
      phoneNumber: goveoStore.phoneNumber
        ? goveoStore.phoneNumber.replace(/\s/g, "")
        : goveoStore.phoneNumber,
      email: goveoStore.email,
      companyName: goveoStore.companyName,
      cif: goveoStore.cif,
      billingAddress: goveoStore.billingAddress,
      billingEmail: goveoStore.email,
      billingPhoneNumber: goveoStore.billingPhoneNumber,
      shippingTypes: goveoStore.shippingTypes,
      paymentGateway: goveoStore.paymentGateway,
      schedule: goveoStore.schedule,
      subCategories: goveoStore.subCategories,
    };

    if (goveoStore.category) {
      storeToUpdate.categoryId = goveoStore.category;
    }

    if (mainImageUrl) {
      storeToUpdate.mainImage = mainImageUrl;
    }

    if (avatarUrl) {
      storeToUpdate.avatar = avatarUrl;
    }

    const storeSnap = await getDoc(doc(goveoStoresRef, goveoStore.id));
    if (!storeSnap.exists()) {
      return updateDoc(doc(goveoStoresToConfirmRef, goveoStore.id), storeToUpdate);
    }

    return updateDoc(doc(goveoStoresRef, goveoStore.id), storeToUpdate);
  };

  this.get = async id => {
    const storeSnap = await getDoc(doc(goveoStoresRef, id));

    if (!storeSnap.exists) {
      return getDoc(doc(goveoStoresToConfirmRef, id));
    }

    return storeSnap;
  };
}
