<template>
  <v-app>
    <div class="form-container">
      <FormulateInput
              type="image"
              name="images"
              v-model="images"
              label="Imágenes"
              validation="mime:image/jpeg,image/jpg,image/png"
              multiple
      />

      <FormulateInput
              label="Título"
              v-model="title"
              type="text"
              validation="required|min:5"
      />

      <FormulateInput
              label="Description"
              v-model="description"
              type="textarea"
              validation="required|min:5"
      />

      <FormulateInput
              label="Precio"
              v-model="price"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              validation="bail|required|number|min:0.5"
      />

      <FormulateInput
              label="¿Es una oferta?"
              type="checkbox"
              v-model="isOffer"
      />

      <FormulateInput
              v-if="isOffer"
              v-model="priceOffer"
              label="Precio oferta"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              validation="bail|required|number|min:0.5"
      />

      <FormulateInput
              label="¿Se puede comprar online?"
              type="checkbox"
              v-model="isAvailableOnline"
      />

      <FormulateInput
              v-if="isAvailableOnline"
              label="Elige una de las dos opciones"
              type="radio"
              v-model="shipmentInfoType"
              :options="{ weight: 'Peso', measures: 'Medidas' }"
      />

      <FormulateInput
              v-if="isAvailableOnline && hasWeight"
              label="Peso del producto (Kg)"
              v-model="weight"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              validation="required|number"
      />

      <FormulateInput
              v-if="isAvailableOnline && !hasWeight"
              name="measures"
              type="group"
              v-model="measures"
      >
        <FormulateInput
                label="Largo (cm)"
                name="length"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                validation="required|number"
        />

        <FormulateInput
                label="Ancho (cm)"
                name="width"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                validation="required|number"
        />

        <FormulateInput
                label="Altura (cm)"
                name="height"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                validation="required|number"
        />
      </FormulateInput>

      <button @click="onSubmitted">Guardar</button>
    </div>
  </v-app>
</template>

<script>
import * as firebaseApp from "../../core/infra/provider";
import "../../node_modules/@braid/vue-formulate/themes/snow/snow.scss";
import FormulateInput from "@braid/vue-formulate/src/FormulateInput";

// import ProductsRepository from "../../core/infra/productsRepository";
// import addProduct from "../../core/app/addProduct";
// const productsRepository = new ProductsRepository();

import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

async function imageUploader(file, progress, error, options) {
  try {
    const cloudinaryUploaderInstance = axios.create({
      baseURL: "https://api.cloudinary.com/v1_1/goveo"
    });

    let formData = new FormData();
    formData.append("api_key", process.env.VUE_APP_CLOUDINARY_API_KEY);
    formData.append("file", file);
    formData.append(
      "upload_preset",
      process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
    );

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        progress(percentCompleted);
        console.log(percentCompleted);
      }
    };

    const imageResponse = await cloudinaryUploaderInstance.post(
      options.uploadUrl,
      formData,
      config
    );
    return imageResponse.data["secure_url"];
  } catch (e) {
    console.log(e);
    error("Hubo un problema al intentar subir la imagen.");
  }
}

Vue.use(VueFormulate, {
  uploader: imageUploader,
  uploadUrl: "/upload"
});

export default {
  name: "addproduct-form",
  components: { FormulateInput },
  data() {
    return {
      submitting: false,
      isOffer: false,
      images: null,
      title: "",
      description: "",
      price: null,
      priceOffer: null,
      isAvailableOnline: false,
      shipmentInfoType: "weight",
      weight: 0.4,
      measures: {},
      user: {}
    };
  },
  computed: {
    hasWeight() {
      return this.shipmentInfoType === "weight";
    }
  },
  beforeMount: function() {
    this.user = firebaseApp.auth().currentUser;
  },
  methods: {
    async onSubmitted() {
      this.submitting = true;

      try {
        const product = {
          id: uuidv4(),
          images: this.images.files.map((file) => file.path),
          title: this.title,
          description: this.description,
        };

        console.log(product);

        // await addProduct(product, {
        //   productsRepository: productsRepository
        // });
      } catch (e) {
        console.log(e);
      }

      this.submitting = false;
    },
  }
};
</script>

<style>
/*.formulate-input .formulate-input-element {*/
/*max-width: 6em;*/
/*margin-bottom: 0.1em;*/
/*}*/

/*.formulate-input[data-classification=file] .formulate-input-upload-area {*/
/*width: 100%;*/
/*position: relative;*/
/*padding: 3em 0;*/
/*}*/

/*.formulate-file-name {*/
/*display: none;*/
/*}*/
</style>
