<template>
  <div>
    <v-stepper-step
      :editable="editable"
      :complete="currentStep > stepNumber"
      :step="stepNumber"
    >
      Pasarela de pago
      <small>(configuración de pasarela de pago)</small>
    </v-stepper-step>
    <v-stepper-content :step="stepNumber">
      <v-form ref="shippingDataForm">
        <v-switch
          color="primary"
          v-model="paymentGateway.enabled"
          label="Activar pasarela de pago con Stripe"
        ></v-switch>
        <div v-if="paymentGateway.enabled">
          <a v-if="!paymentGateway.stripeUserId" :href="`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${$envConfig.stripeClientId}&scope=read_write&state=${goveoStore.id}+${$token}&redirect_uri=${$envConfig.api}/subspayment/connect`">
            <img src="../../assets/stripe_connect.png">
          </a>
        </div>
        <v-btn
          block
          :loading="submitting"
          color="primary"
          @click="next">{{ submitText }}</v-btn
        >
      </v-form>
    </v-stepper-content>
  </div>
</template>

<script>

export default {
  props: [
    "stepNumber",
    "currentStep",
    "goveoStore",
    "validated",
    "editable",
    "submitText",
    "submitting"
  ],
  data: function() {
    return {
      termsAccepted: false,
      paymentGateway: {
        enabled: this.goveoStore.paymentGateway ? this.goveoStore.paymentGateway.enabled || false : false,
        publicKey: this.goveoStore.paymentGateway ? this.goveoStore.paymentGateway.publicKey || null : null,
        stripeUserId: this.goveoStore.paymentGateway ? this.goveoStore.paymentGateway.stripeUserId || null : null,
      }
    };
  },
  methods: {
    next() {
      const validation = this.validate();
      if (validation) {
        this.$emit("onValidated", validation);
        this.$emit("onEnabledPaymentGateway", this.paymentGateway);
        this.$emit("next");
      } else {
        this.$emit("invalid");
      }
    },
    validate() {
      if (!this.paymentGateway.enabled) {
        return true;
      }
      return (this.paymentGateway.publicKey && this.paymentGateway.publicKey.length);
    }
  }
};
</script>

<style lang="scss">
.required label::after {
  content: "*";
  color: red;
}

  .howtostripe-container {
    margin-bottom: 20px;
  }
</style>
