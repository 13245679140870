<template>
    <div>
        <v-stepper-step :editable="editable" :complete="currentStep > stepNumber" :step="stepNumber">
            Seleccionar categoría
        </v-stepper-step>
        <v-stepper-content :step="stepNumber">
            <div>
                <v-chip-group
                        v-model="selectedChip"
                        column
                        active-class="primary--text">
                    <v-chip v-for="tag in tags" :key="tag">
                        {{ tag }}
                    </v-chip>
                </v-chip-group>
            </div>
            <br/>
            <v-btn block :disabled="!valid" :loading="submitting" color="primary" @click="next">{{submitText}}</v-btn>
            <v-btn v-if="hasBack" text @click="back">Volver atrás</v-btn>
        </v-stepper-content>
    </div>
</template>

<script>
    import GoveoCategoryRepository from '../../../core/infra/goveoCategoryRepository';
    import fetchCategories from '../../../core/app/fetchCategories';

    const categoriesRepository = new GoveoCategoryRepository();

    export default {
        props: ['stepNumber', 'currentStep', 'editable', 'submitText', 'hasBack', 'preselectedCategory', "submitting"],
        created: async function (){
          await this.populateCategories();
          this.selectedChip = Object.keys(this.tags).indexOf(this.preselectedCategory);
        },
        data: function () {
            return {
                categories:[],
                tags: {},
                selectedChip: null
            }
        },
        computed: {
            valid() {
              return this.selectedChip !== null;
            },
            selectedCategory() {
                const idSelected = `${Object.keys(this.tags)[this.selectedChip]}`;

                return idSelected !== 'undefined' ? {
                  id: idSelected,
                  type: this.categories[this.categories.findIndex((category) => category.id === idSelected)] ? this.categories[this.categories.findIndex((category) => category.id === idSelected)].type : null
                } : null;
            }
        },
        watch: {
          selectedCategory(selectedCategory) {
                if (selectedCategory) {
                  this.$emit('onSelectCategory', selectedCategory);
                }
            }
        },
        methods: {
            back() {
                this.$emit('back');
            },
            next() {
                this.$emit('next');
            },
            async populateCategories() {
                this.categories = await fetchCategories(categoriesRepository);
                const parsedCategories = {};
                this.categories.forEach((category) => {
                    parsedCategories[`${category.id}`] = category.name;
                });

                this.tags = parsedCategories;
            }
        },
        components: {}
    }
</script>
