export const create = (categoryAttr) => ({
    id: categoryAttr.id,
    name: categoryAttr.name,
    description: categoryAttr.description,
    reference: categoryAttr.reference,
    image: categoryAttr.image,
    type: categoryAttr.type,
    order: categoryAttr.order,
    subCategories: categoryAttr.subCategories,
});
